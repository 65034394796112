import UserAvatar from '@components/User/UserAvatar';
import { usePostContext } from '@context/PostContext';
import { Box, Divider, Grid } from '@mui/material';
import PostManager from '@provider/Controller/post/PostManager';
import { makeStyles } from '@theme/makeStyles';
import { useThemeContext } from '@theme/ThemeContext';
import React from 'react';
import { POST_AVATAR_SIZE_PX } from 'src/Constants';

const POST_LAYOUT_AVATAR_MARGIN_PX = 10;
export const POST_LAYOUT_AVATAR_WIDTH_PX = POST_LAYOUT_AVATAR_MARGIN_PX + POST_AVATAR_SIZE_PX;
export const POST_LAYOUT_AVATAR_WIDTH_MOBILE_PX = POST_LAYOUT_AVATAR_MARGIN_PX + POST_AVATAR_SIZE_PX + POST_LAYOUT_AVATAR_MARGIN_PX;

const POST_LAYOUT_SIZE_PX = 1;
const POST_LAYOUT_MARGIN_TOP_PX = 11;
const POST_LAYOUT_MARGIN_BOTTOM_PX = 8;

export const POST_LAYOUT_DIVIDER_SIZE = POST_LAYOUT_SIZE_PX + POST_LAYOUT_MARGIN_TOP_PX + POST_LAYOUT_MARGIN_BOTTOM_PX;

const useStyles = makeStyles()(() => ({
    avatar: {
        margin: '0 auto',
        width: `${POST_AVATAR_SIZE_PX}px`,
        height: `${POST_AVATAR_SIZE_PX}px`
    },
    postLayoutAvatar: {
        width: `${POST_LAYOUT_AVATAR_WIDTH_PX}px`,
        paddingRight: `${POST_LAYOUT_AVATAR_MARGIN_PX}px`
    },
    postLayoutAvatarMobile: {
        width: `${POST_LAYOUT_AVATAR_WIDTH_MOBILE_PX}px`,
        paddingLeft: `${POST_LAYOUT_AVATAR_MARGIN_PX}px`
    },
    postLayoutContent: {
        width: `calc(100% - ${POST_LAYOUT_AVATAR_WIDTH_PX}px)`
    },
    postLayoutContentMobile: {
        width: `calc(100% - ${POST_LAYOUT_AVATAR_WIDTH_MOBILE_PX}px)`
    },
    postLayoutContentRepost: {
        width: '100%'
    },
    divider: {
        marginTop: `${POST_LAYOUT_MARGIN_TOP_PX}px`,
        marginBottom: `${POST_LAYOUT_MARGIN_BOTTOM_PX}px`,
    }
}));

interface PostLayoutProps {
    withoutAvatar?: boolean;
    children: JSX.Element;
    post: PostManager;
    isRepost: boolean;
}

const PostLayout = (props: PostLayoutProps): JSX.Element => {
    const {classes, cx} = useStyles();
    const {post, withoutAvatar = false, children, isRepost} = props;

    const {isDesktop} = useThemeContext();
    const {goToProfile, inPostPage} = usePostContext();
    
    return (
        <>
            <Grid container alignItems={'flex-start'}>
                {!withoutAvatar && (
                    <Grid item className={cx(classes.postLayoutAvatar, !isDesktop && classes.postLayoutAvatarMobile)}>
                        <UserAvatar 
                            username={[post.data.username]} 
                            avatarUrl={[post.data.avatarUrl]}
                            onClick={goToProfile} 
                            aria-label='Click user avatar post'
                            className={classes.avatar}
                        />
                    </Grid>
                )}

                <Grid 
                    item
                    pr={isDesktop ? 0 : 1}
                    className={isRepost ? classes.postLayoutContentRepost : (isDesktop ? classes.postLayoutContent : classes.postLayoutContentMobile)}
                >
                    {children}
                </Grid>
            </Grid>

            {!isRepost && !inPostPage && (
                <Box className={classes.divider}>
                    <Divider/>
                </Box>
            )}
        </>
    );
};

export default PostLayout;