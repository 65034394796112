import React from 'react';
import { useAppContext } from '@context/AppContext';
import { useUpdaterContext } from '@context/UpdaterContext';
import PostReadyEvent from '../../EventBus/Event/Post/PostReadyEvent';
import EventBus from '../../EventBus/EventBus';
import PostManager from '../../Provider/Controller/post/PostManager';
import Post from '../../Provider/Data/Model/Post';
import { getId } from 'src/Utils/id';

const useRealtimePostStatus = (post?: PostManager, enabled = true):void => {
    const [, updater] = useUpdaterContext();
    const {authUser} = useAppContext();

    // Each instance of this hook should have its own id to prevent listeners from overlapping
    const id = React.useRef<string>(getId().toString());
    const listenerId = post && `post-status-${post.data.id}-${id.current}`;

    
    // Check for media status
    React.useEffect(() => {
        if (enabled && authUser && post && (post.data.status === 'transcoding' || post.data.status === 'pending_approval')) {
            // Remove will overwrite an existing listener. This should ensure we always have the latest reference regardless of async race conditions
            EventBus.remove('post_updated', listenerId);
            EventBus.on('post_updated', listenerId, (p: Post) => {
                if (p.id === post.data.id) {
                    if (p.status === 'rejected' || p.status === 'ok') {
                        // kernel.unsubscribe(`post-${p.id}`);
                        EventBus.remove('post_updated', listenerId);
                    }
                    // fuck it
                    post.refresh().then(() => {
                        updater();
                        if (p.status === 'pending_approval') {
                            EventBus.dispatch(new PostReadyEvent(p.id));
                        }
                    });
                }
            });
        }

        return () => {
            if (post && authUser && enabled) {
                EventBus.remove('post_updated', listenerId);
            }
        };
    }, [post, enabled]);
};

export default useRealtimePostStatus;