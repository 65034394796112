import React from 'react';
import PostManager from '../../../Provider/Controller/post/PostManager';
import {instanceOf} from 'prop-types';
import {Box, Tooltip} from '@mui/material';
import GlyphDollarTip from '../../../icons/dollar-tip.svg';
import { TipModalData } from '../../Tip/TipModal';
import {useAppContext} from '../../../Context/AppContext';
import SVGImage from '../../Utils/SVGImage';
import { useDialog } from '../../../Context/ModalContext';
import { useTranslation } from 'react-i18next';
import { usePostUser } from '@components/Post';

interface TipProps {
    post: PostManager;
    color?: string;
}

const Tip = (props: TipProps): JSX.Element => {
    const {post, color} = props;
    const {authUser, authenticated} = useAppContext();
    const [openTip] = useDialog<TipModalData>('TipModal');
    const {t} = useTranslation('common'); 
    
    const user = usePostUser(post);
    
    const handleTip = () => {
        if (!post.data.banned && user) {
            openTip({ user: user.username });
        }
    };

    return (
        <>
            {authenticated && post.data.influencer && post.data.userId !== authUser.data.id && (
                <Tooltip title={t('TIP')} arrow disableTouchListener>
                    <Box p={0.5} style={{ cursor: 'pointer' }} aria-label='tip from post' onClick={handleTip}>
                        <SVGImage svg={GlyphDollarTip} hoverStroke width={26} height={23} color={color} />
                    </Box>
                </Tooltip>
            )}
        </>
    );
};

Tip.propTypes = {
    post: instanceOf(PostManager).isRequired
};

export default Tip;