import React from 'react';
import {instanceOf} from 'prop-types';
import PostManager from '../../../Provider/Controller/post/PostManager';
import {CardMedia, LinearProgress, Theme, Typography } from '@mui/material';
import {makeStyles} from '@theme/makeStyles';
import { useTranslation } from 'react-i18next';
import {IMAGE_ASPECT_RATIO, POST_BORDER_RADIUS} from '../../../Constants';
import HiddenPostMedia from './HiddenPostMedia';
import ImageSlider from './ImageSlider';
import { useAppContext } from '@context/AppContext';
import useRealtimePostStatus from '../../../Hooks/Logic/useRealtimePostStatus';
import { RenderFileProps } from '@components/ImageMedia/useRenderFile';
import {useMediaContext} from '@context/MediaContext';
import PushPinIcon from '@mui/icons-material/PushPin';
import EyePurchaser from '@components/Utils/Media/EyePurchaser';
import { usePostUser } from '@components/Post';
import {reduxUserToManager} from '../../../Redux/reduxUtils';

const useStyles = makeStyles()((theme: Theme) => ({
    media: {
        height: 0,
        paddingTop: (1/IMAGE_ASPECT_RATIO)*100 + '%',
        backgroundColor: theme.palette.primary.contrastText,
        position: 'relative',
        borderTopLeftRadius: POST_BORDER_RADIUS,
        borderTopRightRadius: POST_BORDER_RADIUS
    },
    loadingText: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        textAlign: 'center'
    },
    pinned: {
        // eslint-disable-next-line custom-rules/no-hardcoded-color-use-styles
        color: '#eae2e2',
        transform: 'rotate(-50deg)',
        position: 'absolute',
        left: theme.spacing(2),
        top: theme.spacing(2),
        zIndex: 2
    },
    noIcons: {
        right: `${theme.spacing(2)} !important`
    },
    // It's used because we have a lot of parts on the web where we use the <subscribe> component and has diff colours.
    forceDark: {
        color: `${theme.palette.common.black} !important`
    }
}));

const PostMedia = (props: PostMediaProps): JSX.Element => {
    const {post, ...renderFileProps} = props;
    const {classes, cx} = useStyles();
    const {authUser} = useAppContext();
    const { withPinned } = useMediaContext();
    const {t} = useTranslation('post');

    useRealtimePostStatus(post);

    const user = usePostUser(post);

    // Wait for user to load
    if (!user) {
        return <div/>;
    }

    if (post.data.status === 'transcoding') {
        return (
            <CardMedia className={classes.media}>
                <LinearProgress variant={'indeterminate'} />
                <Typography className={classes.loadingText} variant={'h3'} color={'secondary'}>
                    <strong>
                        {t('YOUR_MEDIA_IS_BEING_PROCESSED')}.<br/>
                    </strong>
                    {t('IT_WILL_BE_SHOWN_WHEN_READY')}.
                </Typography>
            </CardMedia>
        );
    }

    if (!post.data.blurred && !post.data.visible && post.data.files.some(f => !f.deleted)) {
        return (
            <HiddenPostMedia post={post} withPinned={withPinned} />
        );
    }

    return <>
        <ImageSlider
            contentInfo={{
                price: post.data.price,
                private: post.data.private,
                isMyContent: authUser && post.data.userId === authUser.data.id,
                status: post.data.status
            }}
            {...renderFileProps}
            purchaseBox={
                !post.data.visible && post.data.blurred && (
                    <EyePurchaser
                        files={post.data.files}
                        user={reduxUserToManager(user)}
                        price={post.data.price}
                        paymentBox={!post.data.allFilesDeleted()}
                        blurred
                    />
                )
            }
        />
        {withPinned && post.data.pinned && <PushPinIcon className={cx(classes.pinned, post.data.visible && classes.noIcons)} />}
    </>;
};

interface PostMediaProps extends RenderFileProps {
    post: PostManager;
}

PostMedia.propTypes = {
    post: instanceOf(PostManager).isRequired
};

export default PostMedia;
