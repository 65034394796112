import { PostProps } from '@components/Post';
import { PROMOTER_ID_QUERY_PARAM } from '@hooks/Logic/promoterLogic';
import React, { createContext, useContext } from 'react';
import { ROOT_ROUTES, SITE_ROUTES } from 'src/Constants/routes';
import { useAppContext } from './AppContext';
import UpdaterContextProvider, { UpdaterFunction } from './UpdaterContext';

export const PostContext = createContext<PostContextData>(null);
export const usePostContext = (): PostContextData => useContext(PostContext);

interface PostContextData {
    goToProfile: () => void;
    isMyPost: boolean;
    inPostPage: boolean;
}

interface PostContextProviderProps extends PostProps {
    children: JSX.Element;
    update: number;
    updater: UpdaterFunction;
}

const PostContextProvider = (props: PostContextProviderProps): JSX.Element => {
    const {post, children, update, updater, isRepost, repostUserId} = props;
    const {router, authUser} = useAppContext();

    const goToProfile = () => {
        const pid = isRepost && repostUserId ? '?' + PROMOTER_ID_QUERY_PARAM + '=' + repostUserId : '';
        
        router.push(ROOT_ROUTES.USER(post.data.username) + pid);
    };
    
    const data = {
        goToProfile,
        isMyPost: authUser && post && authUser.data.id === post.data.userId,
        inPostPage: router.pathname === SITE_ROUTES.POST_PATH
    };

    return (
        <PostContext.Provider value={data}>
            <UpdaterContextProvider update={update} updater={updater}>
                {children}
            </UpdaterContextProvider>
        </PostContext.Provider>
    );
};

export default PostContextProvider;