import React from 'react';
import { instanceOf } from 'prop-types';
import UserManager from '../../../Provider/Controller/user/UserManager';
import { ButtonBase, CardActions, Theme } from '@mui/material';
import {makeStyles} from '@theme/makeStyles';
import UserAvatar from '../../User/UserAvatar';
import { useEventBus } from '../../../EventBus/EventBus';
import { UserHeaderProps } from '../Header';
import SeeMore from '../../../icons/profile/glyphs-see-more-white.svg';
import SVGImage from '../../Utils/SVGImage';
import ProfileMenu from '../Actions/ProfileMenu';
import {useDialog} from '../../../Context/ModalContext';
import {AvatarModalData} from '../../Utils/AvatarModal';
import { useUpdater } from '../../../Context/UpdaterContext';
import SocialMedia from '@components/Profile/SocialMedia';
import DMCA from '@components/Utils/DMCA';

const COVER_HEIGHT = 190; // as in zepplin (only value to change)
export const AVATAR_DIAMETER = COVER_HEIGHT * 0.8; // as in zepplin
const AVATAR_OFFSET = AVATAR_DIAMETER * 0.40; // eyeballed it

const useStyles = makeStyles()((theme: Theme) => ({
    cover: {
        position: 'relative',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(4),
        objectFit: 'contain',
        height: COVER_HEIGHT,
        display: 'flex',
        flexDirection: 'column-reverse',
        marginBottom: theme.spacing(2)
    },
    infoCardHeader: {
        padding: 0,
        alignItems: 'flex-end'
    },
    avatarProfile: {
        width: AVATAR_DIAMETER + 'px!important',
        height: AVATAR_DIAMETER + 'px!important',
        marginBottom: -AVATAR_OFFSET,
        marginLeft: '0 !important'
    },
    bannedCover: {
        backgroundColor: theme.palette.grey[700]
    },
    seeMore: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        padding: theme.spacing(1)
    },
    customSocial: {
        position: 'absolute',
        bottom: theme.spacing(1),
        right: theme.spacing(1),
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'flex-end'
    },
    dmca: {
        marginRight: theme.spacing(1)
    }
}));

const HeaderDesktop = (props: UserHeaderProps): JSX.Element => {
    const { user, withMenu = true, disableOpenAvatar = false, id } = props;
    const {classes, cx} = useStyles();

    const [,rerender] = useUpdater();
    const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
    const [openAvatar] = useDialog<AvatarModalData>('AvatarModal');

    const userBlockStatus = user.visitorStatus.blocked || user.visitorStatus.blockedMe;
    const bannedAndBlocked = user.data.banned && user.data.blockedContent;

    useEventBus([{
        id: 'desktop-header-updated',
        callback: () => rerender(),
        events: ['sugarfans.headerUpdated']
    }]);

    return (
        <div className={cx(classes.cover, bannedAndBlocked ? classes.bannedCover : '')}
            id={id}
            style={{ backgroundImage: bannedAndBlocked ? 'unset' : `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${user.data.header.getPath('header', 'profileImage', 'md').url})` }}>
            {withMenu && (
                <>
                    <ProfileMenu user={user} open={menuOpen} closeHandler={() => setMenuOpen(false)} />

                    <ButtonBase aria-label='open profile menu' onClick={() => setMenuOpen(true)} className={classes.seeMore} data-cy={'open-profile-menu'}>
                        <SVGImage fill='secondary' width={20} height={20} svg={SeeMore} removeStroke />
                    </ButtonBase>
                </>
            )}

            <div className={classes.customSocial}>
                {user.data.dmca && user.data.influencer && (
                    <DMCA username={user.data.username} className={classes.dmca} />
                )}
                <SocialMedia user={user} withName={false} />
            </div>

            <CardActions disableSpacing className={classes.infoCardHeader}>
                <UserAvatar
                    aria-label='profile avatar'
                    username={[user.data.username]}
                    avatarUrl={[user.data.avatarUrlHighRes]}
                    disableOnClick={userBlockStatus}
                    banned={bannedAndBlocked}
                    className={classes.avatarProfile}
                    onClick={disableOpenAvatar ? () => console.log() : () => !userBlockStatus && openAvatar({user: user})}
                />
            </CardActions>
        </div>
    );
};

HeaderDesktop.propTypes = {
    user: instanceOf(UserManager).isRequired
};

export default HeaderDesktop;
