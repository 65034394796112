import React from 'react';
import PostManager from '../../../Provider/Controller/post/PostManager';
import {instanceOf, string} from 'prop-types';
import HiddenMedia from '../../Utils/Media/HiddenMedia';
import { usePostUser } from '@components/Post';
import {reduxUserToManager} from '../../../Redux/reduxUtils';

interface PostThumbnailProps {
    post: PostManager;
    withPinned?: boolean;
}

const HiddenPostMedia = (props: PostThumbnailProps): JSX.Element => {
    const {post, withPinned} = props;
    const user = usePostUser(post);

    return (
        <HiddenMedia 
            withPinned={post.data.pinned && withPinned}
            files={post.data.files} 
            user={reduxUserToManager(user)}
            price={post.data.price} 
        />
    );
};

HiddenPostMedia.propTypes = {
    post: instanceOf(PostManager),
    type: string
};

export default HiddenPostMedia;