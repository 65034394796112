import { Theme, Tooltip, Typography } from '@mui/material';
import {makeStyles} from '@theme/makeStyles';
import React from 'react';
import UserManager from '../../Provider/Controller/user/UserManager';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@icons/social/twitter.svg';
import AmateurIcon from '@icons/social/amateur.svg';
import {instanceOf, arrayOf, string, bool} from 'prop-types';
import clsx from 'clsx';
import SVGImage from '@components/Utils/SVGImage';
import {useThemeContext} from '@theme/ThemeContext';


const useStyles = makeStyles()((theme: Theme) => ({
    socialLinks: {
        display: 'flex',
    },
    socialIcons: {
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary,
            width: '1rem',
            height: '1rem',
            [theme.breakpoints.up('lg')]: {
                width: '1.5rem',
                height: '1.5rem'
            }
        }
    },
    socialDisplay: {
        marginTop: theme.spacing(1),
        display: 'flex',
        marginLeft: theme.spacing(1),
        '&:first-of-type': {
            marginLeft: 0
        }
    },
    socialText: {
        marginLeft: theme.spacing(1/2),
        '& a': {
            fontWeight: 'unset'
        }
    },
    instaMobile: {
        color: theme.palette.common.white,
        width: 20,
        height: 20
    },
    instaDesktop: {
        color: theme.palette.common.white,
        width: 25,
        height: 25
    }
}));

type ValidMedia = 'instagram' | 'twitter' | 'amateur'

type MediaItem = {
    dataKey: string,
    icon: JSX.Element,
    withAt: boolean,
    link: string
}

interface SocialMediaProps {
    user: UserManager,
    media?: ValidMedia[],
    allMedia?: boolean,
    iconSize?: string,
    withName: boolean,
    withTooltip: boolean,
    className?: string,
    customTwitter?: string,
    customAmateur?: string
}

const SocialMedia = (props: SocialMediaProps): JSX.Element => {
    const {user, media, allMedia, iconSize, withName, withTooltip, className, customTwitter, customAmateur} = props;
    const {classes} = useStyles();
    const { isDesktop } = useThemeContext();

    const mediaObject : Record<ValidMedia, MediaItem> = {
        amateur: {
            dataKey: 'amateur',
            icon: <SVGImage svg={AmateurIcon} color={'secondary'} width={isDesktop ? 22 : 20} height={isDesktop ? 26 : 20} removeMargin className={customAmateur} />,
            withAt: true,
            link: 'https://amateur.tv/'
        },

        instagram: {
            dataKey: 'instagram',
            icon: <InstagramIcon className={isDesktop ? classes.instaDesktop : classes.instaMobile} />,
            withAt: true,
            link: 'https://www.instagram.com/'
        },

        twitter: {
            dataKey: 'twitter',
            icon: <SVGImage svg={TwitterIcon} color={'secondary'} fill={'secondary'} width={isDesktop ? 25 : 20} height={isDesktop ? 26 : 20} removeMargin className={customTwitter} />,
            withAt: true,
            link: 'https://twitter.com/'
        }
    };

    let mappedMedia = media;

    if (allMedia) {
        mappedMedia = Object.keys(mediaObject) as ValidMedia[];
    }

    return (
        <div className={clsx(classes.socialLinks, className)}>
            {mappedMedia.map(platform => {
                const platformItem = mediaObject[platform];
                if (!user.data[platformItem.dataKey]) {
                    return null;
                }

                return (
                    <div className={classes.socialDisplay} key={platform} data-cy={platformItem.dataKey}>
                        <Tooltip title={(platformItem.withAt ? '@' : '') + user.data[platformItem.dataKey]} aria-label="social" arrow disableHoverListener={!withTooltip} disableTouchListener={!withTooltip}>
                            <a href={platformItem.link + user.data[platformItem.dataKey]} target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}>
                                <span className={iconSize}>
                                    {platformItem.icon}
                                </span>
                            </a>
                        </Tooltip>

                        {withName && (
                            <Typography variant="h3" color={'primary'} className={classes.socialText}>
                                <a href={platformItem.link + user.data[platformItem.dataKey]} target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}>
                                    {(platformItem.withAt ? '@' : '') + user.data[platformItem.dataKey]}
                                </a>
                            </Typography>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

SocialMedia.defaultProps = {
    media: [],
    allMedia: true,
    iconSize: '',
    withName: true,
    withTooltip: false,
    className: '',
    customTwitter: ''
};

SocialMedia.propTypes = {
    user: instanceOf(UserManager).isRequired,
    media: arrayOf(string),
    allMedia: bool,
    withName: bool,
    iconSize: string,
    withTooltip: bool,
    className: string,
    customTwitter: string
};

export default SocialMedia;