import React, { useState } from 'react';
import PostManager from '@provider/Controller/post/PostManager';
import RepostIcon from '@icons/post/repost-icon.svg';
import Box from '@mui/material/Box';
import SVGImage from '@components/Utils/SVGImage';
import { useTranslation } from 'react-i18next';
import { useDialog } from '@context/ModalContext';
import { AuthenticatedModalData } from '@components/Profile/Actions/AuthenticateModal';
import { useAppContext } from '@context/AppContext';
import RepostDialog from '@components/Repost/RepostDialog';
import { Tooltip } from '@mui/material';
import { usePostUser } from '@components/Post';

interface RepostProps {
    post: PostManager;
    color?: string;
}

const Repost = (props: RepostProps): JSX.Element => {
    const {post, color = 'text-primary'} = props;
    const {t} = useTranslation('post');
    const [openAuthenticatedModal] = useDialog<AuthenticatedModalData>('AuthenticatedModal');

    const {authenticated, authUser} = useAppContext();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const user = usePostUser(post);
    const postNotReady = (post && post.data.status !== 'ok');

    const handleRepostClick = () => {
        if (postNotReady || !user) {
            return;
        }

        if (!authenticated) {
            openAuthenticatedModal({type: 'repost', user: user.username});
            
            return;
        }

        setDialogOpen(true);
    };
    
    return (
        <>
            <Tooltip title={postNotReady ? '' : t('REPOST_PROFILE')} arrow disableTouchListener>
                <Box p={0.5} style={{ cursor: postNotReady ? 'auto':'pointer' }} aria-label='repost' onClick={handleRepostClick}>
                    <SVGImage aria-label='repost' svg={RepostIcon} hoverFill={!postNotReady} width={26} height={23} color={postNotReady ? 'grey-400' : color} fill={postNotReady ? 'grey-400' : color} removeStroke />
                </Box>
            </Tooltip>

            {authUser && <RepostDialog 
                open={dialogOpen} 
                closeHandler={() => setDialogOpen(false)} 
                post={post}
                type={'post'}
                repostAction={text => post.repost(text)}
            />}
        </>
    );
};

export default Repost;