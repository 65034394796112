import { MediaPaymentModalData, usePayPost } from '@components/Payment/MediaPaymentModal';
import { usePostUser } from '@components/Post';
import { AuthenticatedModalData } from '@components/Profile/Actions/AuthenticateModal';
import { SubscriptionBundlesModalData } from '@components/Profile/Actions/Subscribe/SubscriptionBundlesModal';
import { useAppContext } from '@context/AppContext';
import { useDialog } from '@context/ModalContext';
import PostManager from '@provider/Controller/post/PostManager';
import { useCallback, useMemo } from 'react';
import { useTiers } from 'src/Services/SWR';

const usePostModals = (post: PostManager, disableFetchTiers = false): (e?: React.MouseEvent | React.TouchEvent) => void => {
    const [openSubscriptionBundles] = useDialog<SubscriptionBundlesModalData>('SubscriptionBundleModal');
    const [openAuthenticated] = useDialog<AuthenticatedModalData>('AuthenticatedModal');
    const [openMediaPaymentModal] = useDialog<MediaPaymentModalData>('MediaPaymentModal');

    const {authenticated, authUser} = useAppContext();

    const isMyProfile = authenticated && authUser.data.id === post.data.userId;
    const postNotVisible = post.data.private && !post.data.visible;
    const needsTiers = postNotVisible && !disableFetchTiers;

    const {data: tiers, loading: tiersLoading} = useTiers(post.data.userId, needsTiers);
    const user = usePostUser(post);

    const handlePay = usePayPost(post);

    const normalTiers = useMemo(() => {
        if (!needsTiers || tiersLoading || !tiers) {
            return [];
        }

        // Taking all the tiers and removing the one shot tier
        return !disableFetchTiers && postNotVisible && tiers.filter(tier => ((tier.data.oneShot && !tier.data.used) || !tier.data.oneShot) && tier.data.type !== 'freebie');
    }, [tiersLoading, tiers, needsTiers]);

    return useCallback((e: React.MouseEvent | React.TouchEvent) => {
        // Anything that doesn't swipe should also not click
        if (e && (e.target as HTMLElement).classList.contains('swiper-no-swiping')) {
            return;
        }

        // Wait for user available
        if (!user) {
            return;
        }
    
        if (!authenticated && !post.data.visible) {
            openAuthenticated({
                type: post.data.price > 0 ? 'buy_post' : 'subscribe', 
                user: user.username
            });
        } else if (!post.data.visible && !isMyProfile) {
            if (post.data.price > 0) { // PPV
                openMediaPaymentModal({
                    handlePay, 
                    price: post.data.price, 
                    user: user.username,
                    contentType: 'post', 
                    authenticateModalType: 'buy_post' 
                });
            } else { // subscribe to unlock
                // Tiers not yet available
                if (tiersLoading) {
                    return;
                }
    
                openSubscriptionBundles({tiers: normalTiers, user: user.username});
            }
        }
    }, [authenticated, tiersLoading, post, user]);
};

export default usePostModals;