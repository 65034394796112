import Post, { PostProps } from '@components/Post';
import PostHeader, { POST_HEADER_MARGIN_BOTTOM } from '@components/Post/PostHeader';
import PostLayout from '@components/Post/PostLayout';
import { useAppContext } from '@context/AppContext';
import { Theme } from '@mui/material';
import { makeStyles } from '@theme/makeStyles';
import React from 'react';
import RepostUser from './RepostUser';

const useStyles = makeStyles()((theme: Theme) => ({
    repostWrapper: {
        marginTop: theme.spacing(POST_HEADER_MARGIN_BOTTOM)
    }
}));

const RepostPost = (props: PostProps): JSX.Element => {
    const {post, controlledReadMoreRepost, controlledReadMore, isRepost = false, ...rest} = props;
    const {authUser} = useAppContext();
    const {classes} = useStyles();

    const isMyPost = post && authUser && post.data.userId === authUser.data.id;

    return (
        <PostLayout post={post} isRepost={isRepost}>
            <>
                <PostHeader {...props} controlledReadMore={controlledReadMore} disableBookmark disableActions={!isMyPost} />
                <div className={classes.repostWrapper}>
                    {post.repostPost ? (
                        <Post 
                            post={post.repostPost} 
                            {...rest} 
                            isRepost 
                            repostUserId={post && post.data.userId} 
                            withPinned={false} 
                            controlledReadMore={controlledReadMoreRepost}
                        />
                    ) : (
                        <RepostUser user={post.repostUser} repostUserId={post && post.data.userId}/>
                    )}
                </div>
            </>
        </PostLayout>
    );
    
};

export default RepostPost;