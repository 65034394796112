import { Typography, Grid, IconButton, Theme, Chip, Box } from '@mui/material';
import {makeStyles} from '@theme/makeStyles';
import React from 'react';
import { PostProps } from '.';
import {POST_AVATAR_SIZE_PX, ROLES} from '../../Constants';
import { useAppContext } from '../../Context/AppContext';
import { useDialog } from '../../Context/ModalContext';
import RoleHidden from '../Utils/RoleHidden';
import SVGImage from '../Utils/SVGImage';
import UsernameBadge from '../Utils/UsernameBadge';
import { MenuData } from './PostActions/Menu';
import GlyphsSeeMoreDark from '@icons/see-more-real-height.svg';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Markdown from '@components/Markdown';
import { usePostContext } from '@context/PostContext';
import UserAvatar from '@components/User/UserAvatar';
import { useThemeContext } from '@theme/ThemeContext';
import {NON_USER_MD_TOKENS} from '@components/Markdown/NewMarkdown';

export const POST_HEADER_MARGIN_BOTTOM = 1.625;
export const POST_HEADER_CONTENT_MARGIN_TOP = 0;
export const POST_HEADER_HEIGHT_PX = 25;

export const POST_TEXT_FONT_SIZE = '15px';
export const POST_TEXT_LINE_HEIGHT = '19px';
export const POST_TEXT_MARGIN_PX = 5;

const useStyles = makeStyles()((theme: Theme) => ({
    avatarHeader: {
        marginRight: '0.312rem'
    },
    cardActions: {
        marginTop: 0,
        marginRight: 0
    },
    postHeader: {
        height: `${POST_HEADER_HEIGHT_PX}px`,
        padding: 0,
    },
    postHeaderRepost: {
        height: `${POST_AVATAR_SIZE_PX}px`
    },
    postHeaderWrapper: {
        // marginBottom: theme.spacing(POST_HEADER_MARGIN_BOTTOM)
    },
    title: {
        marginRight: '2.212rem',
    },
    avatar: {
        marginRight: theme.spacing(1),
        width: `${POST_AVATAR_SIZE_PX}px`,
        height: `${POST_AVATAR_SIZE_PX}px`
    },
    repostUsername: {
        marginBottom: '-7px'
    },
    timestamp: {
        color: theme.palette.post.timestamp,
        lineHeight: '31px',
        fontSize: '14px'
    },
    menuButton: {
        width: '10px',
        height: '10px',
        padding: '10px'
    },
    postContent: {
        fontSize: POST_TEXT_FONT_SIZE,
        lineHeight: POST_TEXT_LINE_HEIGHT
    },
    alignedHeader: {
        marginTop: '-5px'
    }
}));

interface PostHeaderProps extends PostProps {
    disableBookmark?: boolean;
}

const capitalize = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1);
const postTimestamp = (timestamp: number): string => {
    const date = moment.unix(timestamp).utc().fromNow();

    if (date === 'a few seconds ago') {
        return 'Now';
    }

    if (date === 'hace unos segundos') {
        return 'Ahora';
    }

    return capitalize(date);
};

const PostHeader = (props: PostHeaderProps): JSX.Element => {
    const {post, disableActions, disableBookmark = false, controlledReadMore, isRepost} = props;
    const {authenticated, router} = useAppContext();
    const {isDesktop} = useThemeContext();
    const {classes, cx} = useStyles();
    const [openMenu] = useDialog<MenuData>('PostMenu');

    // No items to show if guest viewing single post page
    const showMenuButton = authenticated || router.query.postId == null;

    const { t } = useTranslation('post');

    const {goToProfile} = usePostContext();

    const showDateBelowUsername = !isDesktop && isRepost;

    return (
        <div className={classes.postHeaderWrapper}>
            <Grid container justifyContent={'space-between'} alignItems={isRepost && 'center'} className={cx(classes.postHeader, isRepost && classes.postHeaderRepost)}>
                <Grid item className={!isRepost && post.data.influencer && classes.alignedHeader}>
                    <Box display={'flex'} alignItems={isRepost && 'center'}>
                        {isRepost && (
                            <UserAvatar
                                username={[post.data.username]}
                                avatarUrl={[post.data.avatarUrl]}
                                onClick={goToProfile}
                                aria-label='Click user avatar post'
                                className={classes.avatar}
                            />
                        )}

                        <Box>
                            <UsernameBadge
                                username={post.data.username}
                                displayName={post.data.displayName}
                                isInfluencer={post.data.influencer}
                                onClick={goToProfile}
                                aria-label='Click username badge post'
                                className={isRepost && !isDesktop && classes.repostUsername}
                                maxUsernameLength={!isDesktop && 15}
                            />

                            {showDateBelowUsername && (
                                <Typography component={'span'} variant={'caption'} mr={1} className={classes.timestamp}>
                                    {postTimestamp(post.data.timestamp)}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Grid>

                {post.data.deleted && (
                    <RoleHidden role={ROLES.ROLE_SUPPORT}>
                        <Grid item>
                            <Chip label={t('POST_IS_DELETED')} color={'primary'}/>
                        </Grid>
                    </RoleHidden>
                )}

                <Grid item alignItems={'center'} className={!isRepost && classes.alignedHeader}>
                    {!showDateBelowUsername && (
                        <Typography component={'span'} variant={'caption'} mr={1} className={classes.timestamp}>
                            {postTimestamp(post.data.timestamp)}
                        </Typography>
                    )}

                    {!disableActions && showMenuButton && (
                        <IconButton onClick={() => openMenu({post, disableBookmark})} aria-label='open post menu' size={'small'} className={classes.menuButton}>
                            <SVGImage svg={GlyphsSeeMoreDark} fill='post-timestamp' size={'sm'} removeMargin removeStroke width={15} height={3} style={{display: 'flex', alignItems: 'center'}} />
                        </IconButton>
                    )}
                </Grid>
            </Grid>

            <Box mt={isRepost ? `${POST_TEXT_MARGIN_PX}px` : POST_HEADER_CONTENT_MARGIN_TOP}>
                <Markdown
                    controlledReadMore={controlledReadMore}
                    text={post.data.content}
                    promoterUserId={post.data.userId}
                    disabledTokens={NON_USER_MD_TOKENS}
                    allowInternalLinks
                    readMore
                    classes={{
                        links: classes.postContent,
                        text: classes.postContent,
                        variables: classes.postContent
                    }} />
            </Box>
        </div>
    );
};

const arePropsEqual = (prevProps: Readonly<PostProps>, nextProps: Readonly<PostProps>): boolean => {
    if (prevProps.className !== nextProps.className) {
        return false;
    }

    const postPropsWithEffects = ['bookmarked', 'deleted'] as const;

    if (postPropsWithEffects.some(prop => prevProps.post[prop] != nextProps.post[prop])) {
        return false;
    }

    return true;
};

export default React.memo(PostHeader, arePropsEqual);
