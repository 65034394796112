import React, {useEffect, useRef, useState} from 'react';
import {instanceOf, func} from 'prop-types';
import UserManager from '../../Provider/Controller/user/UserManager';
import { AppBar, Box, ButtonBase, CardActions, Theme } from '@mui/material';
import {makeStyles} from '@theme/makeStyles';
import BackArrowDark from '../../icons/profile/glyphs-back-arrow-dark.svg';
import SeeMoreDark from '../../icons/profile/glyphs-see-more-dark-try.svg';
import UserAvatar from '../User/UserAvatar';
import {useAppContext} from '../../Context/AppContext';
import ProfileMenu from './Actions/ProfileMenu';
import {useEventBus} from '../../EventBus/EventBus';
import { ROOT_ROUTES } from '../../Constants/routes';
import SVGImage from '../Utils/SVGImage';
import { withDesktopComponent } from '../Utils/withDesktopComponent';
import HeaderDesktop from './Desktop/Header';
import {useDialog} from '../../Context/ModalContext';
import {AvatarModalData} from '../Utils/AvatarModal';
import UsernameBadge from '../Utils/UsernameBadge';
import { useUpdater } from '../../Context/UpdaterContext';
import { NextRouter } from 'next/router';
import SocialMedia from '@components/Profile/SocialMedia';
import DMCA from '@components/Utils/DMCA';

export const USERNAME_MOBILE_HEADER_HEIGHT = 40;
const AVATAR_DIAMETER = 140 * 0.9;
const AVATAR_MOBILE_DIAMETER = AVATAR_DIAMETER * 0.7;
const AVATAR_OFFSET = AVATAR_DIAMETER * 0.20;

const useStyles = makeStyles()((theme: Theme) => ({
    cover: {
        position: 'relative',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        padding: '1.25rem 0.5rem 1.25rem 0.5rem',
        objectFit: 'contain',
        height: 100
    },
    appBar: {
        height: USERNAME_MOBILE_HEADER_HEIGHT
    },
    nav: {
        padding: '0.5rem 1rem 0.25rem 1rem',
        display: 'flex',
        justifyContent: 'space-between'
    },
    avatarProfile: {
        width: AVATAR_MOBILE_DIAMETER + 'px!important',
        height: AVATAR_MOBILE_DIAMETER + 'px!important',
        marginBottom: -AVATAR_OFFSET,
        transform: 'translateY(20%)',
        marginLeft: '0 !important'
    },
    noMovingNav: {
        // eslint-disable-next-line custom-rules/no-hardcoded-color-use-styles
        background: 'transparent',
    },
    movingNav: {
        background: theme.palette.background.paper
    },
    navigationContainer: {
        zIndex: 2,
        position: 'fixed',
    },
    bannedCover: {
        backgroundColor: theme.palette.grey[700]
    },
    usernameBadge: {
        position: 'relative'
    },
    backIcon: {
        fill: 'none !important'
    },
    moreIcon: {
        '& > svg > path': {
            stroke: 'none !important'
        }
    },
    customSocial: {
        position: 'absolute',
        bottom: theme.spacing(0.25),
        right: theme.spacing(2),
        display: 'flex',
        alignItems: 'flex-end'
    },
    dmca: {
        marginRight: theme.spacing(1),
        '& img': {
            width: '60px',
            marginBottom: '2px'
        }
    }
}));

export const goBackArrowComposed = (authenticated: boolean, router: NextRouter, isFirstLoad: boolean) => ():void => {
    if (authenticated) {
        // Coming from external source
        if (isFirstLoad) {
            router.push(ROOT_ROUTES.HOME);
        } else {
            router.back();
        }
    } else {
        // Guest
        router.push(ROOT_ROUTES.HOME);
    }
};

export interface UserHeaderProps {
    user: UserManager;
    withNavigation?: boolean;
    withMenu?: boolean;
    disableOpenAvatar?: boolean;
    id?: string;
}

const HeaderMobile = (props: UserHeaderProps): JSX.Element => {
    const {user, withNavigation = true, withMenu = true, disableOpenAvatar = false, id} = props;
    const {classes, cx} = useStyles();

    const { authenticated, router, isFirstLoad } = useAppContext();
    const headerRef = useRef<HTMLHeadingElement>();

    const [menuOpen, setMenuOpen] = useState(false);
    const [navMoved, setNavMoved] = useState(false);
    const [,rerender] = useUpdater();
    const [openAvatar] = useDialog<AvatarModalData>('AvatarModal');
    const userBlockStatus = user.visitorStatus.blocked || user.visitorStatus.blockedMe;
    const bannedAndBlocked = user.data.banned && user.data.blockedContent;

    useEventBus([{
        id: 'header-height-updated',
        callback: () => rerender(),
        events: ['sugarfans.headerUpdated','sugarfans.user.unBlocked','sugarfans.user.blocked']
    }]);

    // Callback de scroll event
    const scrollHandler = React.useCallback(() => {
        if (router.pathname === '/[username]') {
            setNavMoved(window.scrollY > 0);
        }
    }, [setNavMoved]);

    // Registra/elimina el listener cada vez que cambia el threshold
    useEffect(() => {
        if (router.pathname === '/[username]') {
            window.addEventListener('scroll', scrollHandler, {passive: true});
        }

        return () => window.removeEventListener('scroll', scrollHandler);
    }, [router]);

    return (
        <div className={cx(classes.cover, bannedAndBlocked ? classes.bannedCover:'')} id={id}
            style={{ backgroundImage: bannedAndBlocked ? 'unset':`linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${user.data.headerUrl})`}}
        >
            {withMenu && (<ProfileMenu user={user} open={menuOpen} closeHandler={() => setMenuOpen(false)} />)}

            {withNavigation && (<div className={classes.navigationContainer}>
                <AppBar position={'fixed'} elevation={0} className={cx(classes.noMovingNav, navMoved ? classes.movingNav : classes.noMovingNav, classes.appBar)} ref={headerRef}>
                    <CardActions disableSpacing className={classes.nav}>
                        <ButtonBase aria-label='go back from profile' onClick={goBackArrowComposed(authenticated, router, isFirstLoad)}>
                            <Box p={1} m={-1}>
                                <SVGImage color={navMoved ? 'text-primary' : 'secondary'} width={16} fill={'background-paper'} svg={BackArrowDark} removeMargin className={classes.backIcon}/>
                            </Box>
                        </ButtonBase>

                        {navMoved && (
                            <div className={classes.usernameBadge}>
                                <UsernameBadge typographyProps={{color: 'textPrimary'}} username={user.data.username} displayName={user.data.displayName} isInfluencer={user.data.influencer} />
                            </div>
                        )}

                        <ButtonBase aria-label='open menu' onClick={() => setMenuOpen(true)} data-cy={'open-profile-menu'}>
                            <Box p={1} style={{paddingRight: '.25rem'}} m={-1}>
                                <SVGImage fill={navMoved ? 'text-primary' : 'secondary'} width={18} height={15} svg={SeeMoreDark} className={classes.moreIcon} removeStroke />
                            </Box>
                        </ButtonBase>
                    </CardActions>
                </AppBar>
            </div>)}

            <div className={classes.customSocial}>
                {user.data.dmca && user.data.influencer && (
                    <DMCA username={user.data.username} className={classes.dmca} />
                )}
                <SocialMedia user={user} withName={false} />
            </div>

            <CardActions>
                <UserAvatar
                    aria-label='open avatar'
                    username={[user.data.username]}
                    avatarUrl={[user.data.avatarUrlHighRes]}
                    banned={bannedAndBlocked}
                    disableOnClick={userBlockStatus}
                    className={classes.avatarProfile}
                    onClick={disableOpenAvatar ? () => console.log() : () => !userBlockStatus && openAvatar({user: user})}
                />
            </CardActions>
        </div>
    );
};

HeaderMobile.propTypes = {
    user: instanceOf(UserManager).isRequired,
    handleCollide: func
};

export default withDesktopComponent({
    desktop: HeaderDesktop,
    mobile: HeaderMobile
});
