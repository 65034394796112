import React from 'react';
import { instanceOf } from 'prop-types';
import {Box, CardActions, Theme, Typography} from '@mui/material';
import {makeStyles} from '@theme/makeStyles';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import Link from '@components/Utils/Link';
import UserManager from '@provider/Controller/user/UserManager';
import {useAppContext} from '@context/AppContext';
import countFormatter from '../../Hooks/Utils/countFormatter';
import {SETTINGS_ROUTES, SITE_ROUTES} from '../../Constants/routes';
import { useEventBus } from 'src/EventBus/EventBus';
import { useUpdater } from '@context/UpdaterContext';

const useStyles = makeStyles()((theme: Theme) => ({
    infoCardHeader: {
        padding: 0,
        alignItems: 'flex-end'
    },
    userInfo: {
        width: '100%',
    },
    mb2: {
        marginBottom: theme.spacing(1)
    },
    bigFont: {
        '& div > .MuiTypography-root': {
            fontSize: '80%'
        }
    },
    bigFontText: {
        fontSize: '80%'
    },
    typeCounter: {
        marginLeft: theme.spacing(1),

        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(.5)
        }
    },
    icon: {
        marginLeft: theme.spacing(.55)
    },
    typeCounted: {
        marginLeft: theme.spacing(1)
    }
}));

interface ProfileCounter {
    user: UserManager
}

const ProfileCounter = (props: ProfileCounter): JSX.Element => {
    const { user } = props;
    const {classes, cx} = useStyles();

    const { t } = useTranslation('profile');
    const { authUser, authenticated } = useAppContext();

    const [,updater] = useUpdater();

    useEventBus([{
        id: `follow-counter-${user && user.data.id}`,
        callback: () => user.refresh().then(() => updater()),
        events: ['sugarfans.user.followed', 'sugarfans.user.unfollowed']
    }]);

    const isMyProfile = authenticated && user.data.id === authUser.data.id;
    
    return (
        <CardActions disableSpacing className={classes.infoCardHeader}>
            <Box sx={{flexGrow: 1}}>
                <Grid container display={'row'} spacing={1} className={cx(classes.mb2,classes.bigFont)} flexWrap={'nowrap'}>
                    <Grid item>
                        <div>
                            <Typography variant="h2" color='textPrimary' display={'inline'}>
                                <strong>{countFormatter(user.visitorStatus.photosCount)}</strong>
                            </Typography>

                            <Typography variant="h3" color='textPrimary' display={'inline'} className={classes.typeCounter}>
                                {t('PHOTO', { count: user.visitorStatus.photosCount })}
                            </Typography>


                            <Typography variant="h2" color='textPrimary' display={'inline'} className={classes.typeCounted}>
                                <strong>{countFormatter(user.visitorStatus.videosCount)}</strong>
                            </Typography>

                            <Typography variant="h3" color='textPrimary' display={'inline'} className={classes.typeCounter}>
                                {t('VIDEO', { count: user.visitorStatus.videosCount })}
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item>
                        <div>
                            {isMyProfile ? (
                                <>
                                    <Link href={SETTINGS_ROUTES.FOLLOWERS} removeUnderline>
                                        <Typography variant="h2" color='textPrimary' className={classes.bigFontText} display={'inline'}>
                                            <strong>{countFormatter(user.visitorStatus.followersCount)}</strong>
                                        </Typography>

                                        <Typography variant="h3" color='textPrimary' className={cx(classes.bigFontText, classes.typeCounter)} display={'inline'}>
                                            {t('FOLLOWERS', { count: user.visitorStatus.followersCount })}
                                        </Typography>
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <Typography variant="h2" color='textPrimary' display={'inline'} >
                                        <strong>{countFormatter(user.visitorStatus.followersCount)}</strong>
                                    </Typography>


                                    <Typography variant="h3" color='textPrimary' display={'inline'} className={classes.typeCounter}>
                                        {t('FOLLOWERS', { count: user.visitorStatus.followersCount })}
                                    </Typography>
                                </>
                            )}

                            {authenticated && (isMyProfile && user.data.influencer) ? (
                                <Link href={`${SITE_ROUTES.NOTIFICATIONS}?tab=like`} removeUnderline>
                                    <Typography variant="h2" color='textPrimary' className={cx(classes.bigFontText, classes.typeCounted)} display={'inline'}>
                                        <strong>{countFormatter(user.visitorStatus.likesCount)}</strong>
                                    </Typography>

                                    <Typography variant="h3" color='textPrimary' className={cx(classes.bigFontText, classes.typeCounter)} display={'inline'}>
                                        {t('LIKES', { count: user.visitorStatus.likesCount })}
                                    </Typography>
                                </Link>
                            ): (
                                <>
                                    <Typography variant="h2" color='textPrimary' display={'inline'} className={classes.typeCounted}>
                                        <strong>{countFormatter(user.visitorStatus.likesCount)}</strong>
                                    </Typography>

                                    <Typography variant="h3" color='textPrimary' display={'inline'} className={classes.typeCounter}>
                                        {t('LIKES', { count: user.visitorStatus.likesCount })}
                                    </Typography>
                                </>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </CardActions>
    );
};

ProfileCounter.propTypes = {
    user: instanceOf(UserManager).isRequired
};

export default ProfileCounter;