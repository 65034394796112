import React from 'react';
import { Theme } from '@mui/material/styles';
import {makeStyles} from '@theme/makeStyles';
import SubscriptionTierManager from '../../../../Provider/Controller/tiers/SubscriptionTierManager';
import { useTranslation } from 'react-i18next';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SubscriptionItem from '@components/Profile/Actions/Subscribe/SubscriptionItem';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useAppContext} from '@context/AppContext';
import {useUpdaterContext} from '@context/UpdaterContext';
import UserManager from '@provider/Controller/user/UserManager';
import {useDialog} from '@context/ModalContext';
import {AuthenticatedModalData} from '@components/Profile/Actions/AuthenticateModal';
import { SubscriptionItemModalData } from './SubscriptionItemPaymentModal';

const useStyles = makeStyles()((theme: Theme) => ({
    buttonGroup: {
        width: '100%',
        overflow: 'auto',
        padding: 0,
    },
    accordion: {
        boxShadow: 'none',

        '&:before': {
            // eslint-disable-next-line custom-rules/no-hardcoded-color-use-styles
            backgroundColor: 'transparent'
        }
    },
    accordionSummary: {
        padding: 0,
        minHeight: 'unset',
        margin: 0,

        '& .MuiAccordionSummary-content': {
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(-0.5)
        }
    },
    item: {
        marginTop: theme.spacing(.5) + '!important'
    }
}));

export interface SubscriptionBundlesProps {
    user: UserManager;
    tiers: SubscriptionTierManager[];
    closeHandler?: () => void;
}

const SubscriptionBundles = (props: SubscriptionBundlesProps): JSX.Element => {
    const {classes} = useStyles();
    const {tiers, user, closeHandler} = props;
    const { authenticated } = useAppContext();

    const {t} = useTranslation(['profile', 'dimoco']);

    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [openAuthenticated] = useDialog<AuthenticatedModalData>('AuthenticatedModal');
    const [openSubscriptionItemPayment] = useDialog<SubscriptionItemModalData>('SubscriptionItemPaymentModal');

    // Tiers without freebie and one shot
    const filteredOneShot = tiers.filter(tier => !tier.data.oneShot && tier.data.type !== 'freebie');

    const oneShotTier = tiers.filter(tier => tier.data.oneShot)[0];
    const oneShotTime = oneShotTier && oneShotTier.data.billingInterval + ' ' + t('DAY', { count: oneShotTier.data.billingInterval });

    const standardTier = tiers.filter(tier => !tier.data.oneShot && tier.data.type !== 'freebie')[0];
    const standardTime = standardTier && standardTier.data.billingInterval / 30 + ' ' + t('MONTH', { count: standardTier.data.billingInterval / 30 });

    const handleClickTier = (tier: SubscriptionTierManager) => {
        if (!authenticated) {
            openAuthenticated({type: 'subscribe', user: user});
        } else {
            openSubscriptionItemPayment({
                user: user,
                tier: tier,
                normalTier: filteredOneShot[0],
                finishHandler: () => {
                    updater();

                    if (closeHandler) {
                        closeHandler();
                    }
                }
            });
        }
    };

    const [, updater] = useUpdaterContext();

    return (
        <>
            {oneShotTier && !oneShotTier.data.used && (
                <>
                    <Typography variant={'h2'} color={'textPrimary'}>
                        {t('FLASH_TIER_ONE_USE').toUpperCase()}
                    </Typography>

                    <ToggleButtonGroup
                        exclusive
                        key={oneShotTier.data.id}
                        aria-label='one shot tier'
                        orientation={'vertical'}
                        className={classes.buttonGroup}
                    >
                        <SubscriptionItem tier={oneShotTier} handleChange={handleClickTier} index={0} discount={0} time={oneShotTime} className={classes.item} />
                    </ToggleButtonGroup>
                </>
            )}

            {standardTier && (
                <Box mt={oneShotTier && !oneShotTier.data.used ? 2 : 0}>
                    <Typography variant={'h2'} color={'textPrimary'}>
                        {t('STANDARD_TIER').toUpperCase()}
                    </Typography>

                    <ToggleButtonGroup
                        exclusive
                        key={standardTier.data.id}
                        aria-label='standard tier'
                        orientation={'vertical'}
                        className={classes.buttonGroup}
                    >
                        <SubscriptionItem tier={standardTier} handleChange={handleClickTier} index={1} discount={0} time={standardTime} className={classes.item} />
                    </ToggleButtonGroup>
                </Box>
            )}


            {filteredOneShot.length > 1 && (
                <>
                    <Accordion classes={{root: classes.accordion}} disableGutters expanded={expanded} onChange={() => setExpanded(!expanded)}>
                        <AccordionSummary
                            aria-controls="additional-plans-content"
                            id="additional-plans"
                            className={classes.accordionSummary}
                        >
                            {!expanded && <Typography variant={'h3'} color={'primary'}><strong>{t('SEE_MORE_PLANS')}</strong></Typography>}
                        </AccordionSummary>

                        <AccordionDetails style={{padding: 0}}>
                            <ToggleButtonGroup
                                exclusive
                                aria-label='tier bundle'
                                orientation={'vertical'}
                                className={classes.buttonGroup}
                            >
                                {filteredOneShot.slice(1, filteredOneShot.length).map((tier, index) => {
                                    let planCost = 0;
                                    let minPrice = 0;
                                    let discount = 0;

                                    if (!tier.data.oneShot) {
                                        planCost = (tier.data.price/100) / tier.data.billingInterval/30;
                                        minPrice = (filteredOneShot[0].data.price / 100) / filteredOneShot[0].data.billingInterval/30;
                                        discount = Math.round(-((planCost - minPrice) / minPrice) * 100);
                                    }

                                    const time = `${tier.data.oneShot ? tier.data.billingInterval + ' ' + t('DAY', { count: tier.data.billingInterval })
                                        :
                                        tier.data.billingInterval / 30 + ' ' + t('MONTH', { count: tier.data.billingInterval / 30 })}`;

                                    return (
                                        <SubscriptionItem key={tier.data.id} handleChange={handleClickTier} tier={tier} index={index} discount={discount} time={time} />
                                    );
                                })}
                            </ToggleButtonGroup>
                        </AccordionDetails>
                    </Accordion>

                    {expanded && (
                        <Box mt={1}>
                            <Typography variant={'h3'} color={'primary'} style={{cursor: 'pointer'}} aria-label={'show less plans'} onClick={() => setExpanded(false)}><strong>{t('SEE_LESS_PLANS')}</strong></Typography>
                        </Box>
                    )}
                </>
            )}
        </>
    );
};

export default SubscriptionBundles;
