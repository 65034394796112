import React, { useEffect, useState } from 'react';
import Header from '@components/Profile/Header';
import ProfileInfo from '@components/Profile/ProfileInfo';
import { Box, Grid, Theme } from '@mui/material';
import UserManager from '@provider/Controller/user/UserManager';
import { makeStyles } from '@theme/makeStyles';
import Link from 'next/link';
import { ROOT_ROUTES } from 'src/Constants/routes';
import { PROMOTER_ID_QUERY_PARAM } from 'src/Hooks/Logic/promoterLogic';
import { POST_REPOST_BORDER_PX } from '@components/Post';
import {useEmbedProfile} from '../../Services/SWR';
import {POST_BORDER_RADIUS} from '../../Constants';

interface RepostUserProps {
    user: UserManager;
    repostUserId: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
    wrapper: {
        backgroundColor: theme.palette.repostBackground.background,
        border: `${POST_REPOST_BORDER_PX}px solid ${theme.palette.divider}`,
        borderRadius: POST_BORDER_RADIUS,
        overflow: 'hidden',
        cursor: 'pointer'
    },
    postImage: {
        margin: theme.spacing(.5),
        flexBasis: '100%',
        display: 'flex',
        borderRadius: POST_BORDER_RADIUS,
        overflow: 'hidden'
    },
    loadingImageContainer: {
        overflow: 'hidden',
        background: theme.palette.background.linear,
        borderRadius: '5px',
        backgroundSize: '200% 100%',
        animation: '1.5s shine linear infinite'
    },
    loadingImage: {
        filter: 'blur(30px)',
        visibility: 'hidden'
    },
    hidden: {
        visibility: 'hidden'
    }
}));

const LoadingPosts = (): JSX.Element => {
    const {classes, cx} = useStyles();

    return <>
        <Grid item className={cx(classes.postImage)}><div className={classes.loadingImageContainer}><img width={'100%'} className={classes.loadingImage} src={'/static/img/post/blurredThumb.jpg'} /></div></Grid>
        <Grid item className={cx(classes.postImage)}><div className={classes.loadingImageContainer}><img width={'100%'} className={classes.loadingImage} src={'/static/img/post/blurredThumb.jpg'} /></div></Grid>
        <Grid item className={cx(classes.postImage)}><div className={classes.loadingImageContainer}><img width={'100%'} className={classes.loadingImage} src={'/static/img/post/blurredThumb.jpg'} /></div></Grid>
    </>;
};

const RepostUser = (props: RepostUserProps): JSX.Element => {
    const {user, repostUserId} = props;
    const {classes} = useStyles();

    const [posts, setPosts] = useState<string[]>(user && user.data.visitorStatus.postsCount > 0 ? null : []);

    const {data, loading} = useEmbedProfile(user && user.data.username, user && user.data.visitorStatus.postsCount > 0);

    useEffect(() => {
        if (data && !loading) {
            setPosts(Object.values(data.posts));
        }
    }, [data, loading]);

    return (
        <Link href={ROOT_ROUTES.USER(user.data.username) + '?' + PROMOTER_ID_QUERY_PARAM + '=' + repostUserId}>
            <div className={classes.wrapper}>
                <Header user={user} withNavigation={false} withMenu={false} disableOpenAvatar />
                <Box mt={5}>
                    <ProfileInfo user={user} tiers={[]} disabledElements={{
                        about: true,
                        subscriptionMsg: true,
                        usernameLink: true
                    }}/>
                </Box>


                <Grid container xs={12} wrap={'nowrap'}>
                    {posts === null && <LoadingPosts/>}

                    {posts && posts.map((postUrl, i) => (
                        <Grid item key={i} className={classes.postImage}>
                            <img src={postUrl || '/static/img/post/blurredThumb.jpg'} width={'100%'} className={!postUrl && classes.hidden}/>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </Link>
    );
};

export default RepostUser;
