import React from 'react';
import {Grid, Typography} from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import SubscriptionTierManager from '../../../../Provider/Controller/tiers/SubscriptionTierManager';
import UserManager from '../../../../Provider/Controller/user/UserManager';
import { Theme } from '@mui/material/styles';
import {makeStyles} from '@theme/makeStyles';
import { useTranslation } from 'react-i18next';
import {instanceOf, number, string} from 'prop-types';
import {useAppContext} from '../../../../Context/AppContext';
import useTax from '../../../../Hooks/Utils/useTax';
import useCurrency from '../../../../Hooks/Utils/useCurrency';

const useStyles = makeStyles()((theme: Theme) => ({
    buttonRoot: {
        marginTop: theme.spacing(1) + '!important',
        border: '1px solid !important',
        borderRadius: '6px !important',
        flexGrow: 1,
        textTransform: 'none',
        padding: `${theme.spacing(.75)} ${theme.spacing(1)}`,

        '&:hover': {
            border: `1px solid ${theme.palette.primary.main} !important`
        }
    },
    disabled: {
        background: 'none!important'
    },
    textCurrency: {
        [theme.breakpoints.down('md')]: {
            fontSize: '.7rem'
        }
    },
    textCurrencyAlone: {
        [theme.breakpoints.down('md')]: {
            fontSize: '.7rem'
        }
    },
    textOff: {
        marginLeft: theme.spacing(0.5),
        alignSelf: 'center',
        [theme.breakpoints.down('md')]: {
            fontSize: '.7rem'
        }
    },
    subtitle: {
        marginLeft: theme.spacing(.5),
    },
    text: {
        [theme.breakpoints.down('md')]: {
            fontSize: '.7rem'
        }
    },
    showVAT: {
        [theme.breakpoints.down('md')]: {
            fontSize: '.6rem'
        }
    }
}));

interface SubscriptionBundleItemProps {
    tier: SubscriptionTierManager,
    index: number,
    discount: number,
    time: string,
    className?: string
    handleChange?: (tier: SubscriptionTierManager) => void;
    showVAT?: boolean;
}

const SubscriptionItem = (props: SubscriptionBundleItemProps): JSX.Element => {
    const {tier, index, time, discount, className, handleChange, showVAT = false} = props;
    const {classes, cx} = useStyles();
    const { t } = useTranslation('profile');
    const { authUser } = useAppContext();
    const [tax] = useTax('subscription');
    const currency = useCurrency();
    
    return (
        <ToggleButton key={tier.data.id} size={'large'} aria-label={'select tier'} onClick={() => handleChange && handleChange(tier)} value={index} classes={{ root: cx(classes.buttonRoot, className), disabled: classes.disabled }} data-cy={`tier-${tier.data.id}`}>
            <Grid container alignItems={'center'} alignContent={'center'}>
                <Grid item xs container alignContent={'center'} alignItems={'center'}>
                    <Typography variant={'h2'} color={'primary'} display={'inline'} className={cx(classes.text, showVAT && classes.showVAT)}>{time}</Typography>
                    <Typography variant={'h2'} color={'textPrimary'} display={'inline'} className={cx(classes.text, classes.subtitle, showVAT && classes.showVAT)}><strong>{t('SUBSCRIPTION')}</strong></Typography>
                </Grid>


                <Grid item xs container alignContent={'center'} alignItems={'center'} justifyContent={'flex-end'}>
                    <Typography className={cx(classes.textCurrency, authUser && authUser.data.currency === 'EUR' && classes.textCurrencyAlone, showVAT && classes.showVAT)} color={'textPrimary'} display={'inline'}>
                        <strong>{showVAT ? `${tax(tier.data.price / 100, true)}` : `${currency(tier.data.price/100)}` }</strong>
                    </Typography>

                    {!tier.data.oneShot && discount > 0 && (
                        <Typography className={cx(classes.textOff, showVAT && classes.showVAT)} display={'inline'} color={'textPrimary'}>
                            ({t('GET_PLAN_DISCOUNT', {discount: discount})})
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </ToggleButton>
    );
};

SubscriptionItem.propTypes = {
    tier: instanceOf(SubscriptionTierManager),
    index: number,
    user: instanceOf(UserManager),
    discount: number,
    time: string,
    tierSelected: number,
    className: string
};

export default SubscriptionItem;