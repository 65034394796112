import UserManager from '@provider/Controller/user/UserManager';
import { uniqueId } from 'lodash';
import { useMemo, useState } from 'react';
import UserFollowedEvent, { UserFollowedEventData } from 'src/EventBus/Event/User/UserFollowedEvent';
import UserUnfollowedEvent, { UserUnfollowedEventData } from 'src/EventBus/Event/User/UserUnfollowedEvent';
import EventBus, { useSingleEventBus } from 'src/EventBus/EventBus';
import {useAppContext} from '@context/AppContext';
import {useAppDispatch} from '../../Redux/store';
import {userFollowed, userUnfollowed} from '../../Redux/slices/usersSlice';
import {useEffectNotFirstRender} from '@hooks/Utils/useEffectTimeout';

const useFollowLogic = (user: UserManager, callback?: (following: boolean) => void): [boolean, (e) => void] => {
    const {authUser} = useAppContext();
    const [following, setFollowing] = useState<boolean>(user.data.visitorStatus.following);
    const id = useMemo(() => uniqueId(), []);
    const dispatch = useAppDispatch();

    useSingleEventBus('sugarfans.user.followed', `follow-logic-followed-${user && user.data.id}-${id}`, (data: UserFollowedEventData) => {
        if (user && user.data.id === data.userFollowedId) {
            setFollowing(true);
        }
    });
    useSingleEventBus('sugarfans.user.unfollowed', `follow-logic-unfollowed-${user && user.data.id}-${id}`, (data: UserUnfollowedEventData) => {
        if (user && user.data.id === data.userUnFollowedId) {
            setFollowing(false);
        }
    });

    useEffectNotFirstRender(() => {
        if (!user || !user.data.id) {
            return;
        }

        if (following) {
            dispatch(userFollowed(user.data.id));
        }
        else {
            dispatch(userUnfollowed(user.data.id));
        }
    }, [following]);

    const handleFollow = (e) => {
        if (e) {
            e.stopPropagation();
        }

        if (!user) {
            return;
        }

        const follow = () => {
            setFollowing(true);
            if (authUser) {
                authUser.visitorStatus.increaseFollowing(true);
            }
            
            return user.follow();
        };

        const unfollow = () => {
            setFollowing(false);
            if (authUser) {
                authUser.visitorStatus.increaseFollowing(false);
            }

            return user.unFollow();
        };

        const followsUser = following;
        const promise = followsUser ? () => unfollow() : () => follow();

        promise()
            .then(() => {
                if (callback) {
                    callback(!followsUser);
                }
            })
            .catch(() => {
                if (followsUser) {
                    // Simulate follow event to activate buttons
                    EventBus.dispatch(new UserFollowedEvent(user.data.id));
                    setFollowing(true);

                    if (authUser) {
                        authUser.visitorStatus.increaseFollowing(true);
                    }
                }
                else {
                    // Simulate unfollow event to deactivate buttons
                    EventBus.dispatch(new UserUnfollowedEvent(user.data.id));
                    setFollowing(false);

                    if (authUser) {
                        authUser.visitorStatus.increaseFollowing(false);
                    }
                }
            });
    };

    return [following, handleFollow];
};

export default useFollowLogic;