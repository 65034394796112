const SI_SYMBOL = ['', 'k', 'M', 'MM'];

const countFormatter = (number: number): string => {
    try {
        // determine symbol
        let tier = Math.log10(Math.abs(number)) / 3 | 0;

        // Only approx rounded for 999.950 to 1m
        if (number >= 999950 && number <= 1e6) {
            tier = 2;
        }

        // Only approx rounded for 999.950.000 to 1mm
        if (number >= 999950000 && number <= 1e9) {
            tier = 3;
        }

        // if zero, we don't need a suffix
        if (tier == 0) {
            return number.toString();
        }

        // get suffix and determine scale
        const suffix = SI_SYMBOL[tier];

        const scale = Math.pow(10, tier * 3);

        // scale the number
        const scaled = number / scale;

        // In case that there's no suffix
        if (suffix === undefined) {
            return scaled.toFixed(1);
        }

        // format number and add suffix
        return scaled.toFixed(1) + suffix;
    }
    catch {
        return number + '';
    }
};

export default countFormatter;