import DomainEvent, {DomainEventData} from '../../DomainEvent';
import {SugarFansEvent} from '../../EventBus';

export interface UserPostsHiddenEventData extends DomainEventData {
    userId: string;
}

class UserPostsHiddenEvent extends DomainEvent {
    constructor(userId: string) {
        super({userId: userId}, 'User posts hidden');
    }

    public eventName(): SugarFansEvent {
        return 'sugarfans.user.posts_hidden';
    }
}

export default UserPostsHiddenEvent;