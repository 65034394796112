import React, {useState} from 'react';
import PostManager from '../../../Provider/Controller/post/PostManager';
import {instanceOf} from 'prop-types';
import {Box, Tooltip} from '@mui/material';
import {useAppContext} from '../../../Context/AppContext';
import {useUpdaterContext} from '../../../Context/UpdaterContext';
import { useTranslation } from 'react-i18next';
import GlyphsPostLikeInActive from '../../../icons/glyphs-post-like-in-active.svg';
import GlyphsPostLikeActive from '../../../icons/glyphs-post-like-active.svg';
import SVGImage from '../../Utils/SVGImage';
import { useDialog } from '../../../Context/ModalContext';
import { AuthenticatedModalData } from '../../Profile/Actions/AuthenticateModal';
import { getAffiliateId, usePromoterAction } from 'src/Hooks/Logic/promoterLogic';
import { useSingleEventBus } from 'src/EventBus/EventBus';
import { PostLikedEventData } from 'src/EventBus/Event/Post/PostLikedEvent';
import { PostUnlikedEventData } from 'src/EventBus/Event/Post/PostUnlikedEvent';
import { usePostUser } from '@components/Post';

interface LikeProps {
    post: PostManager;
    isRepost?: boolean;
    repostUserId?: string;
    className?: string;
    size?: number;
    p?: number;
    color?: string;
}

const Like = (props: LikeProps): JSX.Element => {
    const {post, isRepost = false, repostUserId, className, size, p, color} = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [update, updater] = useUpdaterContext();
    const [disable, setDisable] = useState<boolean>(false);
    const [openAuthenticatedModal] = useDialog<AuthenticatedModalData>('AuthenticatedModal');
    const {t} = useTranslation('post');

    const {authenticated} = useAppContext();

    const registerPromoter = usePromoterAction({action: 'like', userId: post.data.userId, postId: post.data.id, promoterId: repostUserId}, false);
    const user = usePostUser(post);

    useSingleEventBus('sugarfans.post.liked', 'like-button-post-liked' + Math.random(), (data: PostLikedEventData) => {
        if (post.data.id === data.postId && !post.data.liked) {
            post.data.like();
            updater();
        }
    });

    useSingleEventBus('sugarfans.post.unliked', 'like-button-post-unliked' + Math.random(), (data: PostUnlikedEventData) => {
        if (post.data.id === data.postId && post.data.liked) {
            post.data.unLike();
            updater();
        }
    });

    const handleLike = () => {
        // Wait for user to load
        if (!user) {
            return;
        }

        const affiliateId = getAffiliateId(post.data.userId);

        if (!authenticated) {
            openAuthenticatedModal({type: 'like', user: user.username});
        } else {
            setDisable(true);
            if (post.data.liked) {
                post.data.unLike();
                updater();
    
                post.unlike().catch(() => {
                    post.data.like();
                    updater();
                })
                    .finally(() => setDisable(false));
            }
            else {
                post.data.like();
                updater();
    
                post.like()
                    .then(() => {
                        if ((isRepost || affiliateId) && post.data.influencer) {
                            registerPromoter();
                        }
                    })
                    .catch(() => {
                        post.data.unLike();
                        updater();
                    })
                    .finally(() => setDisable(false));
            }
        }
    };

    const postNotReady = (post && post.data.status !== 'ok');
    const disabled = disable || postNotReady || !post.data.visible;

    const tooltipNotVisible = !post.data.visible && (post.data.price > 0 ? t('PURCHASE_TO_LIKE') : t('SUBSCRIBE_TO_LIKE'));
    const unlikeTooltip = tooltipNotVisible || t('UNLIKE');
    const likeTooltip = tooltipNotVisible || t('LIKE');

    if (authenticated && post.data.liked) {
        return (
            <Tooltip title={postNotReady ? '' : unlikeTooltip} arrow disableTouchListener>
                <Box p={p !== undefined ? p : 0.5} style={{ cursor: disabled ? 'auto':'pointer' }} aria-label='unlike' onClick={!disabled && handleLike || undefined} className={className} data-cy={'post-unlike'}>
                    <SVGImage aria-label='like' svg={GlyphsPostLikeActive} width={size || 26} height={size || 23} color={disabled ? 'grey-400':'primary'} fill={disabled ? 'grey-400':'primary'} />
                </Box>
            </Tooltip>
        );
    }

    return (
        <Tooltip title={postNotReady ? '' : likeTooltip} arrow disableTouchListener>
            <Box p={p !== undefined ? p : 0.5} style={{ cursor: disabled ? 'auto':'pointer' }} aria-label='like' onClick={!disabled && handleLike || undefined} className={className} data-cy={'post-like'}>
                <SVGImage aria-label='unlike' svg={GlyphsPostLikeInActive} hoverStroke={!disabled} width={size || 26} height={size || 23} color={disabled ? 'grey-400' : color} />
            </Box>
        </Tooltip>
    );
};

Like.propTypes = {
    post: instanceOf(PostManager).isRequired
};

export default Like;