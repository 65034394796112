import React, {useEffect, useRef} from 'react';
import PostManager from '../../Provider/Controller/post/PostManager';
import {instanceOf, string, bool, number} from 'prop-types';
import {Box, Card, Theme} from '@mui/material';
import {makeStyles} from '@theme/makeStyles';
import PostActions from './PostActions/index';
import PostMedia from './PostMedia/index';
import {useUpdater, useUpdaterContext} from '@context/UpdaterContext';
import {useAppContext} from '@context/AppContext';
import {POST_BORDER_RADIUS, ROLES} from '../../Constants';
import MediaContextProvider from '../../Context/MediaContext';
import { useThemeContext } from '@theme/ThemeContext';
import { HEADER_HEIGHT, HEADER_HEIGHT_DESKTOP } from '@theme/theme';
import { BOTTOM_NAVIGATION_HEIGHT } from '../Layout/PrimaryLayout/Navigation';
import useSwiperClick from '../../Hooks/Binders/useSwiperClick';
import { useEventBus } from 'src/EventBus/EventBus';
import RepostPost from '@components/Repost/RepostPost';
import usePostModals from '@hooks/Utils/usePostModals';
import PostContextProvider from '@context/PostContext';
import PostHeader, { POST_HEADER_MARGIN_BOTTOM } from './PostHeader';
import { ImageSliderDots } from '@components/ImageMedia/Dots';
import PostLayout from './PostLayout';
import UnsupportedBrowser from '@components/Post/UnsupportedBrowser';
import User from '@vtsfans/sugarfans.js/build/main/lib/Model/User/User';
import {useAppDispatch, useAppSelector} from '../../Redux/store';
import {selectUser} from '../../Redux/slices/usersSlice';
import getUserById from '../../Redux/slices/usersSlice/thunks/getUserById';

export const POST_SLIDER_DOTS_MARGIN = 1;
export const POST_REPOST_BORDER_PX = 1;

export interface PostProps {
    post: PostManager,
    className?: string,
    disableActions?: boolean,
    initialSlide?: number,
    disableFetchTiers?: boolean,
    postOverlay?: React.ReactNode,
    additionalOffset?: number,
    postOffset?: number,
    repostOffset?: number,
    controlledReadMore?: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
    controlledReadMoreRepost?: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
    removeLikes?: boolean;
    withPinned?: boolean;
    disableClick?: boolean;
    classes?: {
        cardContent?: string;
    };
    isRepost?: boolean;
    repostUserId?: string;
    position?: number;
}

const useStyles = makeStyles()((theme: Theme) => ({
    post: {
        borderRadius: 0,
        overflow: 'visible',
    },
    repost: {
        backgroundColor: theme.palette.repostBackground.background,
        border: `${POST_REPOST_BORDER_PX}px solid ${theme.palette.divider}`,
        borderRadius: POST_BORDER_RADIUS,
        padding: theme.spacing(1)
    },
    likes: {
        paddingLeft: theme.spacing(0.5), // align with icon's spacing(0.5) padding left
        paddingTop: 0, // the icons have a bottom padding of spacing(0.5) and a margin of spacing(0.5) for a total of 1 which is enough
        fontSize: 15
    },
    postedAt: {
        paddingTop: theme.spacing(1),
        width: '100%',
        paddingLeft: theme.spacing(0.5),
        fontSize: 11
    },
    disableClick: {
        pointerEvents: 'none'
    },
    mediaContainer: {
        position: 'relative',
        marginTop: theme.spacing(POST_HEADER_MARGIN_BOTTOM)
    },
    // Needed to have all images without some magical added height to the div
    flex: {
        display: 'flex'
    }
}));

/**
 * Get the user of a post.
 * 
 * @param {PostManager} post Post to get the user from
 * @returns {UserManager} The user of the post
 */
export const usePostUser = (post: PostManager): User => {
    // TODO: Check all usages of the returning user -> IT CAN BE NULL!!!!
    const user = useAppSelector(state => post && selectUser(state.users, post.data.userId));
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!post || !post.data.userId) {
            return;
        }

        if (user) {
            return;
        }

        dispatch(getUserById({userId: post.data.userId}));

    }, []);

    return user;
};

const Post = (props: PostProps): JSX.Element => {
    const [update, updater] = useUpdater();

    return (
        <PostContextProvider update={update} updater={updater} {...props}>
            <PostComponent {...props} />
        </PostContextProvider>
    );
};

const PostComponent = (props: PostProps): JSX.Element => {
    const { 
        post, 
        className, 
        disableActions, 
        disableFetchTiers, 
        disableClick, 
        initialSlide, 
        postOverlay, 
        additionalOffset, 
        postOffset: postOffsetProp, 
        repostOffset,
        withPinned, 
        isRepost = false, 
        repostUserId
    } = props;
    
    const {classes, cx} = useStyles();
    const {authenticated, authUser, drmAvailable} = useAppContext();
    const [,updater] = useUpdaterContext();

    const open = usePostModals(post, disableFetchTiers);

    const postRef = useRef();

    // Refresh post (if pinned) when a post gets pinned/unpinned -> this is because we need to update the pin position
    useEventBus([{
        id: 'post-pin-updater-' + post.data.id,
        events: ['sugarfans.post.pinned', 'sugarfans.post.unpinned', 'sugarfans.post.edited'],
        callback: () => {
            if (post.data.pinned) {
                post.refresh().then(() => updater());
            }
        }
    }]);

    const { isDesktop } = useThemeContext();
    const maxContentHeight = 210;
    const maxActionHeight = 50;
    const maxHeaderHeight = 65;
    const postOffset = postOffsetProp || (isDesktop ? HEADER_HEIGHT_DESKTOP:HEADER_HEIGHT+BOTTOM_NAVIGATION_HEIGHT) + maxContentHeight + maxActionHeight + maxHeaderHeight + additionalOffset;

    const swipable = useSwiperClick(open, 'open post media modal');

    // Repost
    if (post.data.repost) {
        return <RepostPost {...props} postOffset={repostOffset} />;
    }

    // Do not show post if not ready
    if (post.data.status === 'transcoding' && (!authUser || post.data.userId !== authUser.data.id)) {
        return <div />;
    }

    // Do not show post if deleted and not admin
    if (post.data.deleted && (!authUser || authUser.data.hasRole(ROLES.ROLE_ADMIN))) {
        return <div/>;
    }

    // This won't render the post when it changes from not deleted to deleted
    if (authenticated && !authUser.data.hasRole(ROLES.ROLE_SUPPORT) && post.data.deleted) {
        return <div />;
    }

    // Don't show post if it doesn't have files and is supposed to
    if (post.data.files.length === 0 && ['ok', 'pending_approval'].includes(post.data.status)) {
        return <div/>;
    }

    return (
        <Card ref={postRef} className={cx(classes.post, isRepost && classes.repost, className, 'sf-post')} elevation={0} data-id={post.data.id} data-cy={`post-${post.data.id}`}>
            <PostLayout
                post={post}
                withoutAvatar={isRepost}
                isRepost={isRepost}
            >
                <>
                    <PostHeader {...props} />

                    {post.data.isDrm && !drmAvailable ? (
                        <UnsupportedBrowser />
                    ) : (
                        <MediaContextProvider
                            files={post.data.files}
                            initialSlide={initialSlide}
                            withPinned={withPinned}
                        >
                            <>
                                <div
                                    className={cx(
                                        classes.mediaContainer,
                                        post.data.status !== 'transcoding' && (post.data.visible || post.data.blurred) && classes.flex,
                                        disableClick ? classes.disableClick : undefined
                                    )}
                                    style={{cursor: post.data.blurred && !post.data.visible ? 'pointer' : ''}}
                                    {...swipable}
                                >
                                    {post.data.files.length >= 0 && (
                                        <PostMedia post={post} files={post.data.files} visible={post.data.visible} viewportOffset={postOffset} onlyOne={post.data.files.length === 1} />
                                    )}
                                    {postOverlay}
                                </div>

                                {/* Don't show dots if private content is not visible */}
                                {(post.data.visible || post.data.blurred) && !post.data.allFilesDeleted() && post.data.files.length > 1 && (
                                    <Box display={'flex'} justifyContent={'center'} mt={POST_SLIDER_DOTS_MARGIN}>
                                        <ImageSliderDots/>
                                    </Box>
                                )}
                            </>
                        </MediaContextProvider>
                    )}

                    {!disableActions && <PostActions post={post} isRepost={isRepost} repostUserId={repostUserId} disableClick={disableClick} />}
                </>
            </PostLayout>
        </Card>
    );
};

Post.propTypes = {
    post: instanceOf(PostManager).isRequired,
    className: string,
    disableActions: bool,
    initialSlide: number,
    disableFetchTiers: bool,
    removeLikes: bool,
    removeCardHeader: bool,
    disableClick: bool
};

Post.defaultProps = {
    additionalOffset: 0,
    disableActions: false,
    disableFetchTiers: false,
    removeLikes: false,
    removeCardHeader: false,
    disableClick: false
};

export const arePostPropsEqual = (prevProps: Readonly<PostProps>, nextProps: Readonly<PostProps>):boolean => {
    if (prevProps.initialSlide !== nextProps.initialSlide) {
        return false;
    }

    if ((prevProps.controlledReadMore && !nextProps.controlledReadMore) || (!prevProps.controlledReadMore && nextProps.controlledReadMore)) {
        return false;
    }

    if (prevProps.controlledReadMore && nextProps.controlledReadMore && prevProps.controlledReadMore[0] !== nextProps.controlledReadMore[0]) {
        return false;
    }

    if ((prevProps.controlledReadMoreRepost && !nextProps.controlledReadMoreRepost) || (!prevProps.controlledReadMoreRepost && nextProps.controlledReadMoreRepost)) {
        return false;
    }

    if (prevProps.controlledReadMoreRepost && nextProps.controlledReadMoreRepost && prevProps.controlledReadMoreRepost[0] !== nextProps.controlledReadMoreRepost[0]) {
        return false;
    }

    if (prevProps.postOverlay !== nextProps.postOverlay) {
        return false;
    }

    if (prevProps.className !== nextProps.className) {
        return false;
    }

    const postPropsWithEffects = ['visible', 'status', 'liked', 'likes', 'content', 'bookmarked', 'blurred', 'purchased', 'deleted'] as const;

    if (postPropsWithEffects.some(prop => prevProps.post.data[prop] != nextProps.post.data[prop])) {
        return false;
    }

    return true;
};

export default React.memo(Post, arePostPropsEqual);
