import React, { useState } from 'react';
import {bool, func, instanceOf} from 'prop-types';
import BottomMenuItem from '../../BottomMenu/BottomMenuItem';
import BottomMenu from '../../BottomMenu/BottomMenu';
import { useTranslation } from 'react-i18next';
import UserManager from '../../../Provider/Controller/user/UserManager';
import {useAlertContext} from '../../../Context/AlertContext';
import {useAppContext} from '../../../Context/AppContext';
import Tooltip from '../../Utils/Tooltip';
import { ManageUserInListsData } from '../../FansList/ManageUserInlists';
import { useDialog } from '../../../Context/ModalContext';
import { ReportData } from '../../Utils/Report';
import { APP_DOMAIN } from '../../../Constants';
import {ROOT_ROUTES, SETTINGS_ROUTES} from '../../../Constants/routes';
import { AuthPersonStatus } from '@provider/Data/Interfaces/AuthorizedPersonProviderInterface';
import {ShareMenuData} from '@components/Utils/ShareMenu';
import RepostDialog from '@components/Repost/RepostDialog';
import useFollowLogic from '@hooks/Logic/useFollowLogic';
import EventBus from '../../../EventBus/EventBus';
import UserPostsHiddenEvent from '../../../EventBus/Event/User/UserPostsHiddenEvent';
import {useAppDispatch} from '../../../Redux/store';
import {userPostsHidden, userPostsUnHidden} from '../../../Redux/slices/usersSlice';

interface ProfileMenuProps {
    user: UserManager,
    open: boolean;
    closeHandler: () => void;
}

const ProfileMenu = (props: ProfileMenuProps): JSX.Element => {
    const {user, open, closeHandler} = props;
    const {t} = useTranslation('post');
    const {confirm} = useAlertContext();
    const {authenticated, authUser, controllers, notifier, router} = useAppContext();
    const [openReport] = useDialog<ReportData>('ReportMenu');
    const [openMUIL] = useDialog<ManageUserInListsData>('ManageUserInListModal');
    const [authStatus, setAuthStatus] = React.useState<AuthPersonStatus>(null);
    const [openShare] = useDialog<ShareMenuData>('ShareMenu');

    const [repostOpen, setRepostOpen] = useState<boolean>(false);

    const isMyProfile = user && authUser && user.data.id === authUser.data.id;
    const affiliateEnabled = !isMyProfile && user && authUser && user.data.influencer && authUser.data.influencer && user.data.affiliateProgram;

    const [following, handleFollow] = useFollowLogic(user);
    const dispatch = useAppDispatch();

    const visitorStatus = user.visitorStatus;

    // Do not show post if not ready
    if (visitorStatus === null || user === null) {
        return <div/>;
    }

    const handleDecideBlock = () => {
        confirm((visitorStatus.blocked ? t('ARE_YOU_SURE_TO_UNBLOCK') : t('ARE_YOU_SURE_TO_BLOCK')) + ' ' + user.data.username + '?', (visitorStatus.blocked ? t('USER_WILL_BE_UNBLOCKED') : t('YOU_WILL_BE_ABLE_TO_UNBLOCK')), 'outlined')
            .then(() => handleBlock())
            .catch(() => console.log());
    };

    const handleBlock = () => {
        if (visitorStatus.blocked) {
            visitorStatus.setBlocked(false);
            user.unBlock()
                .catch(() => {
                    visitorStatus.setBlocked(true);
                });
        }
        else {
            visitorStatus.setBlocked(true);
            user.block()
                .catch(() => {
                    visitorStatus.setBlocked(false);
                });
        }
    };

    const handleAffiliateCopy = () => {
        if (typeof window !== 'undefined') {
            return navigator.clipboard.writeText(`https://${APP_DOMAIN()}${ROOT_ROUTES.USER(user.data.username)}?pid=${authUser.data.id}`);
        }
    };
    
    const handleAuthPersonRequest = (userId: string) => {
        controllers.authorizedPersonController.requestAuthorizedPerson(userId).then(() => {
            notifier.success(t('AUTH_REQUEST_SENT'));
        }).then(()=> fetchStatus());
    };

    const handleHide = () => {
        const hidden = user.data.postsHidden;

        return Promise.resolve()
            .then(() => {
                if (hidden) {
                    return controllers.userController.unHideUserPosts(user.data.id)
                        .then(() => {
                            user.data.setPostsHidden(false);
                            dispatch(userPostsUnHidden(user.data.id));
                        });
                }

                return controllers.userController.hideUserPosts(user.data.id)
                    .then(() => {
                        user.data.setPostsHidden(true);
                        dispatch(userPostsHidden(user.data.id));
                    });
            })
            .then(() => EventBus.dispatch(new UserPostsHiddenEvent(user.data.id)));
    };

    const fetchStatus = () => {
        controllers.authorizedPersonController.getAuthorizedPersonStatus(user.data.id)
            .then((info) => {
                setAuthStatus(info);
            });
    };

    React.useEffect(() => {
        fetchStatus();
    }, []);

    const getAuthUserDisableReason = () => {
        if (!visitorStatus.followsMe) {
            return t('AUTH_DISABLED_FOLLOW');
        } else if (authStatus.requested) {
            return t('AUTH_DISABLED_REQUESTED');
        }

        return t('AUTH_DISABLED_ACCEPTED');

    };

    const handleGoEditProfile = () => {
        router.push(SETTINGS_ROUTES.ACCOUNT);
    };
    
    return (
        <>
            <BottomMenu open={open} closeHandler={closeHandler}>
                {authenticated && authUser.data.id !== user.data.id && (
                    <>
                        <BottomMenuItem aria-label='report user' color={'primary'} title={t('REPORT_USER')} onClick={() => openReport({manager: user})} id={'report'} />
                        <BottomMenuItem aria-label='block/unblock from profile' color={'primary'} title={visitorStatus.blocked ? t('UNBLOCK') : t('BLOCK')} onClick={() => handleDecideBlock()} id={visitorStatus.blocked ? 'unblock' : 'block'} />
                        {!user.data.visitorStatus.blocked && !user.data.visitorStatus.blockedMe && (
                            <Tooltip title={t('common:USER_BANNED_WARNING', {username: user.data.username})} disabled={!user.data.banned || following}>
                                <span>
                                    <BottomMenuItem aria-label='follow/unfollow from profile' disabledClick={user.data.banned && following} title={following ? t('UNFOLLOW') : t('FOLLOW')} onClick={handleFollow} id={following ? 'unfollow' : 'follow'} />
                                </span>
                            </Tooltip>
                        )}
                        {authUser.data.influencer && authUser.data.id !== user.data.id && <BottomMenuItem aria-label='add user to list from profile' title={t('ADD_TO_LIST', {username: user.data.username})} onClick={() => openMUIL({user})} id={'add-to-list'} />}
                    </>
                )}

                {affiliateEnabled &&
                    <BottomMenuItem aria-label='copy affiliate link' title={t('COPY_AFFILIATE_LINK')} onClick={handleAffiliateCopy} id={'copy-affiliate-link'} />
                }

                {authenticated && authUser.data.influencer && user.data.influencer && authUser.data.id !== user.data.id && authStatus !== null &&
                    <Tooltip title={getAuthUserDisableReason()} disabled={visitorStatus.followsMe && (!authStatus.requested && !authStatus.accepted)}>
                        <span>
                            <BottomMenuItem aria-label='request to become authorized user' disabledClick={!visitorStatus.followsMe || (authStatus.requested || authStatus.accepted)} title={t('REQUEST_AUTH_PERSON')} onClick={() => handleAuthPersonRequest(user.data.id)} id={'request-auth-user'} />
                        </span>
                    </Tooltip>
                }

                {authenticated && user.data.id === authUser.data.id && <BottomMenuItem aria-label='edit profile from profile' title={t('EDIT_PROFILE')} onClick={handleGoEditProfile} id={'edit-profile'} />}

                <BottomMenuItem aria-label='share profile link' title={t('SHARE_PROFILE')} onClick={() => openShare({
                    path: ROOT_ROUTES.USER(user.data.username), 
                    shareThroughMessages: false, 
                    customCopyText: t('COPY_PROFILE_LINK'), 
                    affiliate: affiliateEnabled,
                    repostHandler: authUser && user && user.data.influencer && user.data.affiliateProgram && !isMyProfile ? () => setRepostOpen(true) : undefined
                })} id={'share-profile'} />

                {authenticated && user.data.id !== authUser.data.id && user.visitorStatus.following && (
                    <BottomMenuItem aria-label='bookmark post' title={user.data.postsHidden ? t('SHOW_POSTS_FROM_THIS_USER') : t('HIDE_POSTS_FROM_THIS_USER')} onClick={handleHide} id={user.data.postsHidden ? 'show posts' : 'hide posts'} />
                )}
            </BottomMenu>

            {authUser && <RepostDialog
                closeHandler={() => setRepostOpen(false)}
                open={repostOpen}
                type={'user'}
                user={user}
                repostAction={text => user.repost(text)}
            />}
        </>
    );
};

ProfileMenu.propTypes = {
    user: instanceOf(UserManager).isRequired,
    open: bool.isRequired,
    closeHandler: func.isRequired
};

export default ProfileMenu;