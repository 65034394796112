import Form from '@components/Form';
import Post from '@components/Post';
import { Box, ButtonBase, Dialog, DialogContent, DialogTitle, Theme, Typography } from '@mui/material';
import PostManager from '@provider/Controller/post/PostManager';
import { makeStyles } from '@theme/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectSchema } from 'yup';
import * as yup from 'yup';
import SubmitButtonInput from '@components/Form/Input/SubmitButtonInput';
import SVGImage from '@components/Utils/SVGImage';
import CloseIcon from '@icons/CloseSmall.svg';
import UserManager from '@provider/Controller/user/UserManager';
import RepostUser from './RepostUser';
import { useAppContext } from '@context/AppContext';
import TextareaInput from '@components/Form/Input/TextareaInput';

interface WithPostProps {
    type: 'post';
    post: PostManager;
}

interface WithUserProps {
    type: 'user';
    user: UserManager;
}

interface BaseProps {
    open: boolean;
    closeHandler: () => void;
    onSubmit?: () => void;
    repostAction: (text?: string) => Promise<void>;
}

export type RepostDialogProps = BaseProps & (WithPostProps|WithUserProps);

const useStyles = makeStyles()((theme: Theme) => ({
    dialogPaper: {
        // padding: theme.spacing(2),
        // minWidth: 500
    },
    postContent: {
        paddingBottom: theme.spacing(2)
    },
    content: {
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',

        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    textArea: {
        width: '100%'
    },
    backIcon: {
        top: theme.spacing(2),
        right: 0,
        padding: theme.spacing(1),
        transform: 'translateY(-50%)',
        position: 'absolute',
        display: 'flex'
    },
}));

interface RepostFormValues {
    text: string;
}

const RepostDialog = (props: RepostDialogProps): JSX.Element => {
    const {open, closeHandler, onSubmit, type, repostAction} = props;
    const {classes} = useStyles();
    const {authUser, notifier} = useAppContext();
    
    const {t} = useTranslation(['post', 'uploader']);

    const initialValues = {
        text: ''
    };

    const validationSchema = (): ObjectSchema => {
        return yup.object({
            text: yup.string().ensure()
        }).defined();
    };

    const handleSubmit = (values: RepostFormValues, {setSubmitting}) => {
        setSubmitting(true);

        repostAction(values.text)
            .then(() => notifier.success(type === 'post' ? t('POST_HAS_BEEN_REPOSTED_TO_PROFILE') : t('PROFILE_HAS_BEEN_REPOSTED_TO_PROFILE')))
            .then(() => {
                if (onSubmit) {
                    onSubmit();
                }
                closeHandler();
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return <Dialog open={open} onClose={closeHandler} classes={{paper: classes.dialogPaper}} fullWidth>
        <DialogTitle style={{position: 'relative'}}>
            <ButtonBase aria-label='close fullscreen dialog' onClick={closeHandler} className={classes.backIcon}>
                <SVGImage svg={CloseIcon} width={10} height={10} />
            </ButtonBase>

            <Typography variant={'h3'} color={'textPrimary'}>
                <strong>{type === 'post' ? t('REPOST_POST') : t('REPOST_PROFILE_ACTION')}</strong>
            </Typography>
        </DialogTitle>

        <DialogContent className={classes.content}>
            <Box mb={3}>
                {type === 'post' && (
                    <Post 
                        post={props.post}
                        disableActions
                        removeLikes
                        classes={{cardContent: classes.postContent}}
                        isRepost
                    />
                )}

                {type === 'user' && (
                    <RepostUser user={props.user} repostUserId={authUser && authUser.data.id} />
                )}
            </Box>

            <Form<RepostFormValues> initialValues={initialValues} validationSchema={validationSchema} submitHandler={handleSubmit}>
                <Box mb={2}>
                    <TextareaInput minRows={5} maxLength={1000} name={'text'} placeholder={t('uploader:WRITE_A_DESCRIPTION')} />
                </Box>

                <SubmitButtonInput label={type === 'post' ? t('REPOST_POST') : t('REPOST_PROFILE_ACTION')} fullWidth variant={'contained'} color={'primary'} />
            </Form>
        </DialogContent>
    </Dialog>;
};

export default RepostDialog;