import React, { useState } from 'react';
import PostManager from '../../../Provider/Controller/post/PostManager';
import {instanceOf} from 'prop-types';
import {Box, Tooltip} from '@mui/material';
import GlyphPostShareActive from '../../../icons/glyphs-post-share-in-active.svg';
import { ShareMenuData } from '../../Utils/ShareMenu';
import SVGImage from '../../Utils/SVGImage';
import { useDialog } from '../../../Context/ModalContext';
import { useTranslation } from 'react-i18next';
import {SITE_ROUTES} from '../../../Constants/routes';
import RepostDialog from '@components/Repost/RepostDialog';
import { useAppContext } from '@context/AppContext';

interface Share {
    post: PostManager;
    color?: string;
}

const Share = (props: Share): JSX.Element => {
    const {post, color} = props;
    const [openShare] = useDialog<ShareMenuData>('ShareMenu');

    const [repostOpen, setRepostOpen] = useState<boolean>(false);

    const {t} = useTranslation('common');
    const {authUser} = useAppContext();

    const disabled = (post && post.data.status !== 'ok');
    const isMyPost = post && authUser && post.data.id === authUser.data.id;

    return (
        <>
            <Tooltip title={t('SHARE')} arrow disableTouchListener>
                <Box p={0.5} style={{ cursor: disabled ? 'auto' : 'pointer' }} aria-label='share' onClick={!disabled && (() => openShare({
                    path: SITE_ROUTES.POST(post.data.id),
                    repostHandler: authUser && post && !post.data.private && post.data.influencer && post.data.affiliateProgram && !isMyPost ? () => setRepostOpen(true) : undefined
                })) || undefined}>
                    <SVGImage svg={GlyphPostShareActive} width={26} height={23} hoverStroke color={disabled ? 'grey-400' : color} />
                </Box>
            </Tooltip>

            {authUser && <RepostDialog
                closeHandler={() => setRepostOpen(false)}
                open={repostOpen}
                post={post}
                type={'post'}
                repostAction={text => post.repost(text)}
            />}
        </>
    );
};

Share.propTypes = {
    post: instanceOf(PostManager).isRequired
};

export default Share;