import { Box, Theme, Typography } from '@mui/material';
import {makeStyles} from '@theme/makeStyles';
import React from 'react';
import UserManager from '../../Provider/Controller/user/UserManager';
import About from './About';
import { useAppContext } from '../../Context/AppContext';
import { USERNAME_MOBILE_HEADER_HEIGHT } from './Header';
import UsernameBadge from '@components/Utils/UsernameBadge';
import ProfileCounter from '@components/Profile/ProfileCounter';
import SubscribedMessage from '@components/Profile/Actions/Subscribe/SubscribedMessage';
import SubscriptionBundles from '@components/Profile/Actions/Subscribe/SubscriptionBundles';
import SubscriptionTierManager from '@provider/Controller/tiers/SubscriptionTierManager';

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        width: '100%',
        zIndex: 1,
        position: 'relative'
    },
    tab: {
        maxWidth: '100%',
        padding: 0
    },
    appBar: {
        position: 'absolute',
        top: 0, left: 0, right: 0, bottom: 0
    },
    appBarMoving: {
        position: 'fixed',
        marginTop: USERNAME_MOBILE_HEADER_HEIGHT,
        bottom: 'auto'
    },
    preTabs: {
        padding: theme.spacing(2),
        paddingTop: 0
    },
    socialMedia: {
        paddingLeft: theme.spacing(1)
    },
    followButton: {
        minWidth: 101.15
    },
    actionsMobile: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(2)
    },
    username: {
        marginTop: theme.spacing(-0.5)
    }
}));

interface ProfileInfoProps {
    user: UserManager;
    tiers: SubscriptionTierManager[];
    disabledElements?: {
        subscriptionMsg?: boolean;
        about?: boolean;
        usernameLink?: boolean;
    };
}

const ProfileInfo = (props: ProfileInfoProps): JSX.Element => {
    const {user, tiers, disabledElements} = props;
    const {classes} = useStyles();
    const {authenticated, authUser} = useAppContext();
    
    return (
        <div className={classes.preTabs}>
            <div>
                <UsernameBadge 
                    disableLink={disabledElements && disabledElements.usernameLink} 
                    typographyProps={{variant: 'h2'}} 
                    username={user.data.username} 
                    displayName={user.data.displayName} 
                    isInfluencer={user.data.influencer} 
                />

                <Typography variant='h3' color='textPrimary' className={classes.username}>
                        @{user.data.username}
                </Typography>
            </div>

            <Box mt={1} id={'profile-info-user'}>
                <ProfileCounter user={user} />
            </Box>

            {!(disabledElements && disabledElements.about) && <About user={user} />}

            {authenticated && !(disabledElements && disabledElements.subscriptionMsg) && <SubscribedMessage user={user} />}

            {(user.data.influencer && tiers && tiers.length > 0) && ((authenticated && authUser && !user.data.visitorStatus.subscribed) || (!authenticated && !authUser)) && (
                <Box mt={user.data.about.length > 0 ? 1 : 0}>
                    <SubscriptionBundles tiers={tiers} user={user} />
                </Box>
            )}
        </div>
    );
};

export default ProfileInfo;
