import React from 'react';
import {instanceOf} from 'prop-types';
import {Box, CardActions, Grid, Theme, Typography} from '@mui/material';
import Like from './Like';
import Tip from './Tip';
import PostManager from '../../../Provider/Controller/post/PostManager';
import Share from './Share';
import { makeStyles } from '@theme/makeStyles';
import Repost from './Repost';
import countFormatter from '@hooks/Utils/countFormatter';
import { useDialog } from '@context/ModalContext';
import { LikesPreviewModalData } from '@components/Utils/LikesPreviewModal';
import { usePostContext } from '@context/PostContext';
import { useAppContext } from '@context/AppContext';

const POST_ACTIONS_HEIGHT_PX = 36;
const POST_ACTIONS_MARGIN_PX = 7;

export const POST_ACTIONS_HEIGHT = POST_ACTIONS_HEIGHT_PX + POST_ACTIONS_MARGIN_PX;

interface PostActionsProps {
    post: PostManager;
    isRepost?: boolean;
    repostUserId?: string;
    disableClick?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
    actions: {
        padding: 0,
        marginTop: `${POST_ACTIONS_MARGIN_PX}px`,
        height: `${POST_ACTIONS_HEIGHT_PX}px`,
    },
    pointer: {
        cursor: 'pointer'
    },
    postAction: {
        color: theme.palette.post.light,
    }
}));

const PostActions = (props: PostActionsProps): JSX.Element => {
    const {post, isRepost = false, repostUserId, disableClick} = props;
    const {classes, cx} = useStyles();

    const {isMyPost} = usePostContext();
    const {authenticated} = useAppContext();

    const [openLikesPreviewModal] = useDialog<LikesPreviewModalData>('LikesPreviewModal');
    const refLikes = React.useRef(null);

    const likesClickEnabled = !disableClick && isMyPost && post.data.likes > 0;

    return (
        <CardActions disableSpacing className={classes.actions}>
            <Grid container justifyContent='space-around' alignItems='center'>
                {authenticated && post.data.influencer && !post.data.banned && !isMyPost && (
                    <Grid item>
                        <Tip post={post} color={'primary'} />
                    </Grid>
                )}
                
                <Grid item>
                    <Box display={'flex'} alignItems={'flex-start'}>
                        <Like p={0} post={post} isRepost={isRepost} repostUserId={repostUserId} className={classes.postAction} color={'post-light'} />

                        <Typography 
                            aria-label={'open likes preview'} 
                            ref={refLikes} 
                            variant={'h3'}
                            fontSize={'15px'}
                            className={cx(classes.postAction, likesClickEnabled && classes.pointer)}
                            onClick={() => likesClickEnabled && openLikesPreviewModal({postId: post.data.id})}
                        >
                            {countFormatter(post.data.likes)}
                        </Typography>
                    </Box>
                </Grid>

                {post.data.influencer && post.data.affiliateProgram && !post.data.private && (
                    <Grid item>
                        <Repost post={post} color={'post-light'}/>
                    </Grid>
                )}

                <Grid item>
                    <Share post={post} color={'post-light'}/>
                </Grid>
            </Grid>

        </CardActions>
    );
};

PostActions.propTypes = {
    post: instanceOf(PostManager).isRequired,
};

export default PostActions;