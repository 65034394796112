import DomainEvent, {DomainEventData} from '../../DomainEvent';
import {SugarFansEvent} from '../../EventBus';

export interface PostReadyEventData extends DomainEventData {
    postId: string;
}

class PostReadyEvent extends DomainEvent {
    constructor(postId: string) {
        super({postId: postId});
    }

    public eventName(): SugarFansEvent {
        return 'sugarfans.post.ready';
    }
}

export default PostReadyEvent;