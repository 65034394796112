import React, { useMemo } from 'react';
import {instanceOf, string} from 'prop-types';
import UserManager from '../../Provider/Controller/user/UserManager';
import {Theme, Typography} from '@mui/material';
import {makeStyles} from '@theme/makeStyles';
import {useSingleEventBus} from '../../EventBus/EventBus';
import Markdown from '../Markdown';
import {useThemeContext} from '@theme/ThemeContext';
import { useTranslation } from 'react-i18next';
import {NON_USER_MD_TOKENS} from '@components/Markdown/NewMarkdown';

const useStyles = makeStyles()((theme: Theme) => ({
    noCollapse: {
        [theme.breakpoints.up('lg')]: {
            '& *': {
                fontSize: '0.9rem'
            }
        },
        wordWrap: 'break-word'
    }
}));

interface UserProps {
    user: UserManager,
    customSocialMedia?: string
}

const About = (props: UserProps): JSX.Element => {
    const {user} = props;
    const {classes} = useStyles();
    const { isDesktop } = useThemeContext();
    const {t} = useTranslation('profile');

    const [showMore, setShowMore] = React.useState<boolean>(false);

    useSingleEventBus('sugarfans.user.updated', 'about-updated', () => user.refresh());

    const threshold = useMemo(() => {
        const normalThreshold = isDesktop ? 150 : 100;
        const lineBreakThreshold = 6;

        if (user) {
            const nbLineBreaks = (user.data.about.match(/\n/g) || []).length;

            if (nbLineBreaks > lineBreakThreshold) {
                return Math.min(user.data.about.indexOf('\n') + lineBreakThreshold, normalThreshold);
            }
        }

        return normalThreshold;

    }, [user && user.data.about, isDesktop]);

    const handleDecideDescription = (): string => {
        if (user.data.about.length > threshold) {
            if (showMore) {
                return user.data.about;
            }

            return `${user.data.about.substr(0, threshold)}...`;
        }

        return user.data.about;
    };

    return <div className={classes.noCollapse}>
        <Markdown
            disabledTokens={NON_USER_MD_TOKENS}
            allowInternalLinks
            text={handleDecideDescription()}
            cypress={'user-bio'}
        />

        {user.data.about.length > threshold && (
            <Typography variant={'h3'} color={'primary'} style={{cursor: 'pointer'}} display={'inline'} aria-label={'see more/less'} onClick={() => setShowMore(!showMore)}>
                <strong>{showMore ? t('SEE_LESS') : t('SEE_MORE')}</strong>
            </Typography>
        )}
    </div>;
};

About.propTypes = {
    user: instanceOf(UserManager).isRequired,
    customSocialMedia: string
};

About.defaultProps = {
    customSocialMedia: ''
};

export default About;
